import { ActionContext, ActionTree } from 'vuex';
import { IAuthedUser, IChangePassword } from '@/modules/authentication/auth.interface';
import { ERootMutations } from '@/modules/common/common.enum';
import { IRootState } from '@/modules/common/common.interface';
import { IErrorResponse } from '@/modules/error/error.interface';
import { IUser, IUserApi, IUserProfile } from '@/modules/user/user.interface';
import Api from '@/services/api/ApiFactory';
import { ResourceResponse } from '@/services/api/common/Index';
import { AuthenticationState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<AuthenticationState, IRootState> = {
  LOGIN_WITH_PASSWORD(
    { commit, dispatch }: ActionContext<AuthenticationState, IRootState>,
    authedpayload: IAuthedUser
  ): Promise<IUserProfile> {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .loginWithPassword(authedpayload)
        .then((response: ResourceResponse<IUserProfile>) => {
          commit(ERootMutations.SET_AUTH_STATUS_SUCCESS, 'success', { root: true });
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  RESET_PASSWORD({ dispatch }: ActionContext<AuthenticationState, IRootState>, payload: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .resetPassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  VALIDATE_PASSWORD_RESET_TOKEN(
    { dispatch }: ActionContext<AuthenticationState, IRootState>,
    payload: string
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .validatePasswordResetToken(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CHANGE_PASSWORD_WITH_TOKEN(
    { dispatch }: ActionContext<AuthenticationState, IRootState>,
    payload: IChangePassword
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .changePasswordWithToken(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  SET_CSRF_TOKEN({ dispatch }: ActionContext<AuthenticationState, IRootState>): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .setCsrfToken()
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  VERIFY_EMAIL_TOKEN(
    { dispatch }: ActionContext<AuthenticationState, IRootState>,
    payload: string
  ): Promise<ResourceResponse<IUser>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .verifyToken(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  SEND_EMAIL_VERIFICATION_LINK(): Promise<ResourceResponse<IUserApi>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .sendEmailVerificationLink()
        .then((response: ResourceResponse<IUserApi>) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          const error: IErrorResponse = errorResponse?.response?.data ?? errorResponse;
          const errorObj: IErrorResponse = {
            code: error.code,
            status: error.status,
            message: error.message
          };
          const errorMessage = errorObj.message
            ? errorObj.message.charAt(0).toUpperCase() + errorObj.message.slice(1)
            : '';
          reject(errorMessage);
        });
    });
  },
  REFRESH_AUTH_TOKEN({
    commit,
    dispatch
  }: ActionContext<AuthenticationState, IRootState>): Promise<ResourceResponse<void>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getAuthentication()
        .refreshAuth()
        .then(async (response) => {
          commit('REFRESHED_AUTH_TOKEN', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

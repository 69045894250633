import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { IShortUrlState } from './types';

const state: IShortUrlState = {
  paginated_short_urls: null,
  selected_short_url: null,
  updated_short_url: null
};

// eslint-disable-next-line import/prefer-default-export
export const shortUrl: Module<IShortUrlState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

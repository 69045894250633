import { MutationTree } from 'vuex';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IQrSearchResponse } from '@/modules/tech/tech.interface';
import { IMetaDataFieldsResponse } from '@/modules/metadata/metadata.interface';
import { ITechState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ITechState> = {
  UPDATE_SEARCHED_QR_CODES(
    state: ITechState,
    searched_qr_codes: ResourceResponse<IQrSearchResponse>
  ) {
    state.searched_qr_codes = searched_qr_codes;
  },
  UPDATE_TECH_META_DATA(
    state: ITechState,
    tech_meta_data: ResourceResponse<IMetaDataFieldsResponse>
  ) {
    state.tech_meta_data = tech_meta_data;
  }
};

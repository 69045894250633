import { GetterTree } from 'vuex';
import { IAudienceList } from '@/modules/audience/audience.interface';
import { IRootState } from '@/modules/common/common.interface';
import { AudienceState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<AudienceState, IRootState> = {
  paginated_audience_list(state): IAudienceList {
    return state.paginated_audience_list;
  }
};

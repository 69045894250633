import { ISystemLookups } from '@/modules/lookup/lookup.interface';
import { ETrackTraceLookups } from '@/modules/lookup/lookup.enum';
import { TCompanyLookup, TCompanyLookups, TTrackTraceLookups } from '@/modules/lookup/lookup.type';

import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class Lookup {
  private readonly client: ClientInterface;

  private readonly resource: string = 'lookups';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getSystemLookups(): Promise<ResourceResponse<ISystemLookups>> {
    return this.client.get(`${this.resource}/system`);
  }

  getCompanyLookups(lookups: TCompanyLookup[]): Promise<TCompanyLookups> {
    return this.client.get(`${this.resource}/company?lookups=${lookups}&sortBy=createdAt`);
  }

  getTrackTraceLookups(
    lookups: ETrackTraceLookups[]
  ): Promise<ResourceResponse<TTrackTraceLookups>> {
    return this.client.get(`${this.resource}/tracktrace?lookups=${lookups}`);
  }
}

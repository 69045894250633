import { IGeographics } from '@/modules/dashboard/dashboard.interface';
import ClientInterface from '@/services/api/common/ClientInterface';

export default class Geography {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/scans/geography';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPopularCountriesAndCities(from: Date, to: Date): Promise<IGeographics> {
    return this.client.get<IGeographics>(`${this.resource}?from=${from}&to=${to}`);
  }
}

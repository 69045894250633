import { IProduct, IProducts } from '@/modules/product/product.interface';
import ClientInterface from '@/services/api/common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';

export default class Product {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/scans/product';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getMostPopularProducts(from: Date, to: Date): Promise<IProducts> {
    return this.client.get<IProducts>(`${this.resource}?from=${from}&to=${to}`);
  }

  getPaginatedProducts(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IProduct[]>> {
    builder.setResource('product');
    return this.client.getPaginated<IProduct[]>(builder);
  }
}

import { EAnalyticsType } from '@/modules/common/service.enum';
import { TQrType } from '@/modules/qr-generator/link-config.interface';
import { ETechBatchType } from '@/modules/tech-batch/tech-batch.enum';
import Company from '@/services/api/Company';
import { TIntegrationType } from '@/views/private/integration/types/type';
import Account from './Account';
import Analytics from './Analytics';
import AnalyticsReport from './AnalyticsReport';
import AudienceTable from './Audience';
import Authentication from './Authentication';
import BrandProtection from './BrandProtection';
import Campaign from './Campaign';
import DashboardCampaign from './DashboardCampaign';
import Demography from './Demography';
import Email from './Email';
import EngagedProduct from './EngagedProduct';
import Form from './Form';
import Geography from './Geography';
import Integration from './Integration';
import Link from './Link';
import Lookup from './Lookup';
import Media from './Media';
import Metadata from './Metadata';
import Misc from './Misc';
import ProductV2 from './NewProduct';
import Notification from './Notification';
import Product from './Product';
import Profile from './Profile';
import Qr from './Qr';
import Report from './Report';
import Scan from './Scan';
import ScansByLocations from './ScansByLocation';
import Signup from './Signup';
import Statistics from './Statistics';
import TechBatch from './TechBatch';
import Technology from './Technology';
import TimeSeries from './TimeSeries';
import TrackTrace from './TrackTrace';
import User from './User';
import Client from './common/Client';
import ShortUrl from './ShortUrl';

export default class ApiFactory {
  private readonly client: Client;

  private readonly clientv1: Client;

  private readonly clientv2: Client;

  private readonly clientauth: Client;

  private readonly apiPathV1 = `${import.meta.env.VITE_APP_CONNECT_NEXT_API}`;

  constructor() {
    this.client = new Client(this.apiPathV1);
    this.clientv1 = new Client(`${this.apiPathV1}v1`);
    this.clientauth = new Client(`${this.apiPathV1}v1`, false);
    this.clientv2 = new Client(`${this.apiPathV1}v2`);
  }

  getCompany(): Company {
    return new Company(this.client);
  }

  getDashboardTimeSeries(): TimeSeries {
    return new TimeSeries(this.client);
  }

  getDashboardStats(): Statistics {
    return new Statistics(this.client);
  }

  getProducts(): Product {
    return new Product(this.client);
  }

  getGeography(): Geography {
    return new Geography(this.client);
  }

  getDemography(): Demography {
    return new Demography(this.client);
  }

  getEngagementProducts(): EngagedProduct {
    return new EngagedProduct(this.client);
  }

  getEngagementCampaigns(): DashboardCampaign {
    return new DashboardCampaign(this.client);
  }

  getTechnology(): Technology {
    return new Technology(this.clientv2);
  }

  getScansByLocation(): ScansByLocations {
    return new ScansByLocations(this.clientv2);
  }

  getScan(): Scan {
    return new Scan(this.clientv2);
  }

  getAudience(): AudienceTable {
    return new AudienceTable(this.client);
  }

  getReport(): Report {
    return new Report(this.client);
  }

  getBrandProtection(): BrandProtection {
    return new BrandProtection(this.client);
  }

  getAnalytics(resource: EAnalyticsType): Analytics {
    return new Analytics(this.client, resource);
  }

  getAnalyticsReport(): AnalyticsReport {
    return new AnalyticsReport(this.clientv2);
  }

  getProductsV2(): ProductV2 {
    return new ProductV2(this.clientv2);
  }

  getMisc(): Misc {
    return new Misc(this.clientv2);
  }

  getMedia(): Media {
    return new Media(this.client);
  }

  getAuthentication(): Authentication {
    return new Authentication(this.clientauth);
  }

  getUser(): User {
    return new User(this.clientv1);
  }

  getAccount(): Account {
    return new Account(this.clientv1);
  }

  getProfile(): Profile {
    return new Profile(this.clientv1);
  }

  getTechBatch(techType: ETechBatchType): TechBatch {
    return new TechBatch(this.clientv1, techType);
  }

  getLookup(): Lookup {
    return new Lookup(this.clientv1);
  }

  getTech(): Qr {
    return new Qr(this.clientv2);
  }

  getCampaign(): Campaign {
    return new Campaign(this.clientv2);
  }

  getTrackTrace(): TrackTrace {
    return new TrackTrace(this.clientv1);
  }

  getMetadata(): Metadata {
    return new Metadata(this.clientv1);
  }

  getNotifications(): Notification {
    return new Notification(this.clientv1);
  }

  getForm(): Form {
    return new Form(this.clientv1);
  }

  getEmail(): Email {
    return new Email(this.clientv1);
  }

  getSignUp(): Signup {
    return new Signup(this.clientv1);
  }

  getLink(qrType?: TQrType): Link {
    return new Link(this.clientv1, qrType);
  }

  getIntegration(type?: TIntegrationType): Integration {
    return new Integration(this.clientv1, type);
  }

  getShortUrl(): ShortUrl {
    return new ShortUrl(this.clientv1);
  }
}

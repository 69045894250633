import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { ITechState } from './types';
import { actions } from './actions';
import { mutations } from './mutations';

const state: ITechState = {
  searched_qr_codes: null,
  tech_meta_data: null
};

// eslint-disable-next-line import/prefer-default-export
export const tech: Module<ITechState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

import { IPaginateQuery } from '@connect-saas/util/common';

export default class FormSubmissionQueryBuilder {
  private filters: { [column: string]: string | string[] } = {};

  private sortBy: [string, string][] = [];

  private searchBy: string[] = [];

  private search?: string;

  private resource: string;

  public setResource(resource: string): FormSubmissionQueryBuilder {
    this.resource = resource;
    return this;
  }

  public addFilter(
    field: string,
    operator: string,
    value: string | string[]
  ): FormSubmissionQueryBuilder {
    // if value is already a string (like for 'createdAt'), use it directly
    if (typeof value === 'string') {
      this.filters[field] = `${operator}:${value}`;
    } else if (Array.isArray(value)) {
      // in case of array, join it, though it shouldn't reach here for 'createdAt'
      this.filters[field] = `${operator}:${value.join(',')}`;
    }

    return this;
  }

  public clearFilter(): void {
    this.filters = {};
  }

  public setSortBy(field: string, direction: 'ASC' | 'DESC' = 'ASC'): FormSubmissionQueryBuilder {
    this.sortBy.push([field, direction]);
    return this;
  }

  public setSearch(search: string): FormSubmissionQueryBuilder {
    this.search = search;
    return this;
  }

  public addSearchBy(field: string): FormSubmissionQueryBuilder {
    this.searchBy.push(field);
    return this;
  }

  public buildQuery(): IPaginateQuery {
    return {
      sortBy: this.sortBy.length > 0 ? this.sortBy : undefined,
      searchBy: this.searchBy.length > 0 ? this.searchBy : undefined,
      search: this.search,
      filter: Object.keys(this.filters).length > 0 ? this.filters : undefined,
      path: this.resource
    };
  }
}

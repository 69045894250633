import { IAudienceList } from '@/modules/audience/audience.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';

export default class AudienceTable {
  private readonly client: ClientInterface;

  private readonly resource: string = 'lead';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedAudience(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IAudienceList>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IAudienceList>(builder);
  }

  getAudienceExport(search: string): Promise<void> {
    return this.client.get(`${this.resource}/csv?search=${search}`);
  }
}

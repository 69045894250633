/* eslint-disable import/prefer-default-export */
import { MutationTree } from 'vuex';
import { IOSList } from '@/modules/analytics/analytics/analytics.interface';
import {
  IAnalyticsScanStat,
  IBrowserList
} from '@/modules/analytics/technology/technology.interface';
import { TechnologyState } from './types';

export const mutations: MutationTree<TechnologyState> = {
  UPDATE_TECHNOLOGY_ANALYTICS_SCANS(state, technology_scan_data: IAnalyticsScanStat) {
    state.technology_scan_data = technology_scan_data;
  },
  UPDATE_TECHNOLOGY_BROWSER_LIST(state: TechnologyState, technology_browser_list: IBrowserList) {
    state.technology_browser_list = technology_browser_list;
  },
  UPDATE_TECHNOLOGY_OS_LIST(state: TechnologyState, technology_os_list: IOSList) {
    state.technology_os_list = technology_os_list;
  }
};

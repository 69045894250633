import '@mdi/font/css/materialdesignicons.css';
import { useCookies } from '@vueuse/integrations/useCookies';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import 'vuetify/styles';

const host: string = window?.location?.hostname;
const cookies = useCookies(['locale']);
const isEcocanHost = cookies?.get('is-ecocan-host');
let primaryColor = '#040933';
let primaryDarken1 = '#02051F';
let primaryLighten1 = '#686B85';
let primaryLighten2 = '#9B9DAD';
let primaryLighten3 = '#CDCED6';
if (host.indexOf('bsidigitrace.com') > -1) {
  primaryColor = '#D72D18';
}
if (host.indexOf('ecocanafrica.com') > -1 || isEcocanHost) {
  primaryColor = '#228b22';
  primaryDarken1 = '#0E380E';
  primaryLighten1 = '#7AB97A';
  primaryLighten2 = '#A7D1A7';
  primaryLighten3 = '#D3E8D3';
}

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: primaryColor,
          'primary-darken-1': primaryDarken1,
          'primary-lighten-1': primaryLighten1,
          'primary-lighten-2': primaryLighten2,
          'primary-lighten-3': primaryLighten3,
          'primary-grey': '#EEEEEE',
          'pale-peach': '#F8D8D0',
          greendecrease: '#3DD598',
          reddecrease: '#FC5A5A',
          error: '#D72D18',
          'bsi-red': '#D72D18',
          'sheen-green-darken-1': '#70A838',
          'sheen-green-lighten-4': '#F2F9EC',
          'negative-red-lighten-4': '#FBEAE8',
          'honey-yellow': '#FDBC11',
          'honey-yellow-lighten-4': '#FDF5EB',
          'royal-blue-lighten-4': '#ECF0F9',
          'light-green': '#E2EED5',
          'tangerine-red': '#F05C2B',
          'ecocan-dark-green': '#094c31',
          'ecocan-dark-gray': '#373a35',
          'ecocan-light-gray': '#094c31',
          'ecocan-light-green': '#b9f98f'
        }
      },
      dark: {
        dark: true,
        colors: {
          background: '#FFFFFF',
          primary: primaryColor
        }
      }
    }
  }
});

import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
// eslint-disable-next-line import/order
import { ActionTree } from 'vuex';
import { BrandProtectionState } from './types';

const ApiFactory = new Api();
const builder = new GeohashScansBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<BrandProtectionState, IRootState> = {
  GET_BRAND_PROTECTION_RULES({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getBrandProtectionRules()
        .then((response) => {
          commit('UPDATE_BRAND_PROTECTION_RULES', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_BRAND_PROTECTION_RULE_BY_ID({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getBrandProtectionRuleById(id)
        .then((response) => {
          commit('SELECTED_BRAND_PROTECTION_RULE', response.resource);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_BRAND_PROTECTION_RULE({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .createAlertRule(payload)
        .then((response) => {
          dispatch('GET_BRAND_PROTECTION_RULES');
          dispatch('GET_BRAND_PROTECTION_RULE_BY_ID', response.resource.id);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_BRAND_PROTECTION_RULE({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .deleteAlertRule(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPDATE_SELECTED_BRAND_PROTECTION_RULE({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .updateAlertRule(payload.id, payload)
        .then((response) => {
          commit('SELECTED_BRAND_PROTECTION_RULE', response);
          dispatch('GET_BRAND_PROTECTION_RULE_BY_ID', payload.id);
          dispatch('GET_BRAND_PROTECTION_RULES');
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  STOP_SELECTED_BRAND_PROTECTION_RULE({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .stopAlertRule(payload)
        .then((response) => {
          dispatch('GET_SCANS_OVER_TIME_REPORT');
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_SCANS_OVER_TIME_REPORT({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getScansOverTimeReport()
        .then((response) => {
          commit('UPDATE_SCANS_OVER_TIME_REPORT', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_SCANS_OVER_TIME_LOCATIONS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setBoundingBox(payload.boundingBox);
      ApiFactory.getBrandProtection()
        .getScansOverTimeLocations(builder)
        .then((response) => {
          commit('UPDATE_SCANS_OVER_TIME_LOCATIONS', response.resources);
          resolve(response.resources);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_SCANS_OVER_TIME_COUNTRIES({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getScansOverTimeCountries()
        .then((response) => {
          commit('UPDATE_SCANS_OVER_TIME_COUNTRIES', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ALERT_DETAILS_BY_RULE_ID({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getAlertDetailsByRuleId(id)
        .then((response) => {
          commit('SELECTED_ALERT', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_PAGINATED_PRODUCTS({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      builder.setPageSize(200);
      ApiFactory.getProductsV2()
        .getPaginatedProducts(builder)
        .then((response) => {
          commit('UPDATE_PAGINATED_PRODUCTS', response.resources);
          resolve(response.resources);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_PRODUCT_COUNTRY_SCANS_REPORT({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getProductCountryScansReport()
        .then((response) => {
          commit('UPDATE_PRODUCT_COUNTRY_SCANS_REPORT', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_PRODUCT_COUNTRY_SCANS_MAP({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getBrandProtection()
        .getProductCountryScansMap()
        .then((response) => {
          commit('UPDATE_PRODUCT_COUNTRY_SCANS_MAP', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

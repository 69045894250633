import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import {
  ICompany,
  ICreateCompany,
  ISearchableCompany,
  IUpdateCompany
} from '@/modules/company/company.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { EFilterOperators } from '@/modules/common/pagination.interface';
import { ICompanyState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ICompanyState, IRootState> = {
  GET_PAGINATED_COMPANIES({ commit, dispatch }: ActionContext<ICompanyState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getCompany()
        .getPaginatedCompanies(builder)
        .then((response: PaginatedResponse<ISearchableCompany>) => {
          commit('UPDATE_PAGINATED_COMPANIES', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_COMPANY_BY_ID({ commit, dispatch }: ActionContext<ICompanyState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getCompany()
        .getCompanyById(id)
        .then((response: ResourceResponse<ICompany | null>) => {
          commit('SELECTED_COMPANY', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_COMPANY(
    { commit, dispatch }: ActionContext<ICompanyState, IRootState>,
    payload: ICreateCompany
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getCompany()
        .createCompany(payload)
        .then((response: ResourceResponse<ICompany>) => {
          commit('UPDATE_SELECTED_COMPANY', response.resource);
          commit('CREATED_COMPANY', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SELECTED_COMPANY(
    { commit, dispatch }: ActionContext<ICompanyState, IRootState>,
    payload: { id: string; body: IUpdateCompany }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getCompany()
        .updateCompany(payload.id, payload.body)
        .then((response: ResourceResponse<ICompany>) => {
          commit('UPDATE_SELECTED_COMPANY', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },

  DELETE_SELECTED_COMPANY({ dispatch }: ActionContext<ICompanyState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getCompany()
        .deleteCompanyById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { AnalyticsReportState } from './types';

const state: AnalyticsReportState = {
  update_analytics_report: null,
  selected_analytics_report: null,
  selected_analytics_report_export: null,
  selected_analytics_report_scans_component: null,
  selected_analytics_report_scan_locations: null,
  selected_analytics_report_scan_time_series: null,
  selected_analytics_report_scan_stats: null,
  update_analytics_report_filter_lookups: null
};

// eslint-disable-next-line import/prefer-default-export
export const analyticsreport: Module<AnalyticsReportState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { INotificationState } from './types';

const state: INotificationState = {
  all_notifications: null,
  selected_notification: null,
  show_unread_notifications: localStorage.getItem('showUnreadNotifications')
    ? JSON.parse(localStorage.getItem('showUnreadNotifications'))
    : null,
  show_notification_menu: false
};

// eslint-disable-next-line import/prefer-default-export
export const notification: Module<INotificationState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

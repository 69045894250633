import { IAuthedUser, IUser, IUserApi } from '@connect-saas/types';
import Client from './common/Client';
import ResourceResponse from './common/ResourceResponse';
import { IChangePassword } from '@/modules/authentication/auth.interface';
import { IUserProfile } from '@/modules/user/user.interface';

export default class Authentication {
  private readonly client: Client;

  private readonly resource: string = 'auth/password';

  constructor(client: Client) {
    this.client = client;
  }

  loginWithPassword(payload: IAuthedUser): Promise<ResourceResponse<IUserProfile>> {
    return this.client.post(`${this.resource}/login`, payload);
  }

  resetPassword(payload: string): Promise<ResourceResponse<null>> {
    return this.client.post(`${this.resource}/reset`, { email: payload });
  }

  validatePasswordResetToken(payload: string): Promise<ResourceResponse<null>> {
    return this.client.post(`${this.resource}/reset-validate`, { token: payload });
  }

  changePasswordWithToken(payload: IChangePassword): Promise<ResourceResponse<null>> {
    return this.client.post(`${this.resource}/change`, payload);
  }

  setCsrfToken(): Promise<void> {
    return this.client.get('auth/csrf/token');
  }

  verifyToken(payload: string): Promise<ResourceResponse<IUser>> {
    return this.client.post('auth/verify', { token: payload });
  }

  sendEmailVerificationLink(): Promise<ResourceResponse<IUserApi>> {
    return this.client.post('auth/verify/request');
  }

  refreshAuth(): Promise<ResourceResponse<void>> {
    return this.client.post('auth/refresh');
  }
}

import { MutationTree } from 'vuex';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import {
  ISearchableProtectCodePlusTechBatch,
  ISearchableQrTechBatch,
  ITechBatchProtectCodePlus,
  ITechBatchQr
} from '@/modules/tech-batch/tech-batch.interface';
import { ITechBatchState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ITechBatchState> = {
  UPDATE_PAGINATED_TECH_BATCH(
    state: ITechBatchState,
    paginated_tech_batch: IPaginatedData<ISearchableQrTechBatch>
  ) {
    state.paginated_tech_batch = paginated_tech_batch;
  },
  SELECTED_TECH_BATCH(state: ITechBatchState, selected_tech_batch: ITechBatchQr) {
    state.selected_tech_batch = selected_tech_batch;
  },
  UPDATE_SELECTED_TECH_BATCH(state: ITechBatchState, update_selected_tech_batch: ITechBatchQr) {
    state.update_selected_tech_batch = update_selected_tech_batch;
  },
  UPDATE_PAGINATED_PROTECTCODE_PLUS_TECH_BATCH(
    state: ITechBatchState,
    paginated_protectcode_plus_tech_batch: IPaginatedData<ISearchableProtectCodePlusTechBatch>
  ) {
    state.paginated_protectcode_plus_tech_batch = paginated_protectcode_plus_tech_batch;
  },
  SELECTED_PROTECTCODE_PLUS_BATCH(
    state: ITechBatchState,
    selected_protectcode_plus_batch: ITechBatchProtectCodePlus
  ) {
    state.selected_protectcode_plus_batch = selected_protectcode_plus_batch;
  }
};

import { ICampaignList } from '@/modules/dashboard/dashboard.interface';
import ClientInterface from '@/services/api/common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';

export default class DashboardCampaign {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/engagement/campaign';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedCampaigns(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<ICampaignList>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<ICampaignList>(builder);
  }
}

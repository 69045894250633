import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IFormState } from './types';

const state: IFormState = {
  paginated_forms: null,
  selected_form: null,
  update_selected_form: null,
  paginated_form_submissions: null,
  selected_form_submission_links: null,
  submission_links_list: null,
  update_selected_form_for_lookup: null,
  newly_created_form_for_edit_qr: null
};

// eslint-disable-next-line import/prefer-default-export
export const form: Module<IFormState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

import { IAnalyticsScanTimeSeries } from '@/modules/analytics-report/analytics-report.interface';
import { EGranularity } from '@/modules/common/common.enum';
import { IRootState } from '@/modules/common/common.interface';
import { TPayload } from '@/modules/common/common.type';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
import GeohashScansResponse from '@/services/api/common/GeohashScansResponse';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IPopularProduct, IScan, IScanLocationCount } from '@connect-saas/types';
import { ActionContext, ActionTree } from 'vuex';
import { IMiscState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();
const geoHashScansBuilder = new GeohashScansBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IMiscState, IRootState> = {
  GET_PAGINATED_MISC_SCANS({ commit, dispatch }: ActionContext<IMiscState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      ApiFactory.getScan()
        .getScansByDate(builder, payload.scanType)
        .then((response: PaginatedResponse<IPaginatedData<IScan>>) => {
          commit('UPDATE_PAGINATED_MISC_SCAN', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_STAT_COUNTS({ commit, dispatch }: ActionContext<IMiscState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMisc()
        .getAnalyticsStatCounts(payload)
        .then((response) => {
          commit('UPDATE_ANALYTICS_STAT_COUNTS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_LOCATIONS(
    { commit, dispatch }: ActionContext<IMiscState, IRootState>,
    payload
  ) {
    return new Promise((resolve, reject) => {
      geoHashScansBuilder.setFromDate(payload.from);
      geoHashScansBuilder.setToDate(payload.to);
      if (payload.boundingBox) {
        geoHashScansBuilder.setBoundingBox(payload.boundingBox);
      }

      ApiFactory.getMisc()
        .getAnalyticsScanLocations(payload.scanType, geoHashScansBuilder)
        .then((response: GeohashScansResponse<IScanLocationCount>) => {
          commit('UPDATE_ANALYTICS_SCAN_LOCATIONS', response.resources);
          resolve(response.resources);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_MOST_SCANNED_PRODUCTS(
    { commit, dispatch }: ActionContext<IMiscState, IRootState>,
    payload
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMisc()
        .getMostScannedProducts(payload)
        .then((response: IPopularProduct[]) => {
          commit('UPDATE_ANALYTICS_MOST_SCANNED_PRODUCTS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_TIME_SERIES(
    { commit, dispatch }: ActionContext<IMiscState, IRootState>,
    payload: TPayload<EGranularity>
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getMisc()
        .getAnalyticsScanTimeSeries(payload)
        .then((response: ResourceResponse<IAnalyticsScanTimeSeries>) => {
          commit('UPDATE_ANALYTICS_SCAN_TIME_SERIES', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import {
  IMetadataField,
  IMetadataFieldBase,
  ISearchableMetadataField
} from '@/modules/metadata-admin/metadata.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import { ERootMutations } from '@/modules/common/common.enum';
import { TMetaDataError } from '@/modules/common/common.type';
import { IMetadataState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IMetadataState, IRootState> = {
  GET_PAGINATED_META_DATA(
    { commit, dispatch }: ActionContext<IMetadataState, IRootState>,
    payload
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableMetadataField>>> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getMetadata()
        .getPaginatedMetadata(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableMetadataField>>) => {
          commit('UPDATE_PAGINATED_META_DATA', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_META_DATA(
    { commit, dispatch }: ActionContext<IMetadataState, IRootState>,
    payload: IMetadataFieldBase
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getMetadata()
        .createMetadata(payload)
        .then((response: ResourceResponse<IMetadataField>) => {
          commit('SELECTED_META_DATA', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            const errorPayload: TMetaDataError = {
              error: errorResponse,
              type: payload?.type
            };
            dispatch('THROW_METADATA_ERROR', errorPayload);
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_META_DATA(
    { commit, dispatch }: ActionContext<IMetadataState, IRootState>,
    payload: { id: string; body: IMetadataFieldBase }
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getMetadata()
        .updateMetadata(payload.id, payload.body)
        .then((response: ResourceResponse<IMetadataField>) => {
          commit('SELECTED_META_DATA', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            const errorPayload: TMetaDataError = {
              error: errorResponse,
              type: payload.body?.type
            };
            dispatch('THROW_METADATA_ERROR', errorPayload);
            reject(errorResponse);
          }
        });
    });
  },
  GET_META_DATA_BY_ID(
    { commit, dispatch }: ActionContext<IMetadataState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getMetadata()
        .getMetadataById(id)
        .then((response: ResourceResponse<IMetadataField>) => {
          commit('SELECTED_META_DATA', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_META_DATA(
    { dispatch }: ActionContext<IMetadataState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getMetadata()
        .deleteMetadataById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  THROW_METADATA_ERROR(
    { dispatch, commit }: ActionContext<IMetadataState, IRootState>,
    errorResponse: TMetaDataError
  ) {
    const error = errorResponse?.error?.response?.data ?? errorResponse;
    const errorCode = error.code ?? '';
    if (errorCode === 'ALREADY_EXIST') {
      commit(
        ERootMutations.SHOW_TOAST_MESSAGE,
        {
          message: `This ${errorResponse.type} field name already exists. Please choose a different name and try again.`,
          color: 'error'
        },
        { root: true }
      );
    } else if (error?.message === 'config.maxLength is not valid') {
      commit(
        ERootMutations.SHOW_TOAST_MESSAGE,
        {
          message:
            'Minimum length cannot be equal or greater than Maximum length! Please enter a valid range.',
          color: 'error'
        },
        { root: true }
      );
    } else {
      dispatch('THROW_CUSTOM_ERROR', errorResponse.error, { root: true });
    }
  }
};

import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import { IImageUploadRequest } from '@/modules/image/image.interface';
import {
  IChangeUserPasswordParams,
  ICreateUserParams,
  ISearchableUser,
  IUpdateUserProfileParams,
  IUser,
  IUserProfile
} from '@/modules/user/user.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IUserState } from './types';
import { IUserAllowance } from '@connect-saas/types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IUserState, IRootState> = {
  GET_PAGINATED_USERS({ commit, dispatch }: ActionContext<IUserState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getUser()
        .getPaginatedUsers(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableUser>>) => {
          commit('UPDATE_PAGINATED_USERS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_USER_BY_ID({ commit, dispatch }: ActionContext<IUserState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .getUserById(id)
        .then((response: ResourceResponse<IUser | null>) => {
          commit('SELECTED_USER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_USER(
    { commit, dispatch }: ActionContext<IUserState, IRootState>,
    payload: ICreateUserParams
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .createUser(payload)
        .then((response: ResourceResponse<IUser>) => {
          commit('UPDATE_SELECTED_USER', response.resource);
          const getUserPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          dispatch('GET_PAGINATED_USERS', getUserPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SELECTED_USER(
    { commit, dispatch }: ActionContext<IUserState, IRootState>,
    payload: { id: string; body: IUpdateUserProfileParams }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .updateUser(payload.id, payload.body)
        .then((response: ResourceResponse<IUser>) => {
          commit('UPDATE_SELECTED_USER', response.resource);
          const getUserPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          dispatch('GET_PAGINATED_USERS', getUserPayload);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_USER({ dispatch }: ActionContext<IUserState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .deleteUserById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CHANGE_USER_PASSWORD(
    { commit, dispatch }: ActionContext<IUserState, IRootState>,
    payload: { id: string; body: IChangeUserPasswordParams }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .changeUserPassword(payload.id, payload.body)
        .then((response: ResourceResponse<IUserProfile>) => {
          commit('UPDATE_SELECTED_USER', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPLOAD_USER_PROFILE_IMAGE(
    { commit, dispatch }: ActionContext<IUserState, IRootState>,
    payload: { id: string; body: IImageUploadRequest }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .uploadUserProfileImage(payload.id, payload.body)
        .then((response) => {
          commit('UPDATE_SELECTED_PROFILE_IMAGE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_USER_ALLOWANCE({ commit, dispatch }: ActionContext<IUserState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getUser()
        .getAllowance()
        .then((response: IUserAllowance) => {
          commit('UPDATE_USER_ALLOWANCE', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

import { MutationTree } from 'vuex';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import {
  IForm,
  IFormSubmissionLinkResponse,
  ISearchableFormSubmission
} from '@/modules/form/form.interface';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import { IFormState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IFormState> = {
  UPDATE_PAGINATED_FORMS(state: IFormState, paginated_forms: PaginatedResponse<IForm>) {
    const paginatedList = paginated_forms;
    if (
      state.update_selected_form &&
      !paginated_forms.resources?.some(
        (form: IForm): boolean => form.id === state.update_selected_form.id
      )
    ) {
      paginatedList.resources?.unshift(state.update_selected_form);
    }
    state.paginated_forms = paginatedList;
    state.update_selected_form = null;
  },
  SELECTED_FORM(state: IFormState, selected_form: IForm) {
    state.selected_form = selected_form;
  },
  UPDATE_SELECTED_FORM(state: IFormState, update_selected_form: IForm) {
    state.update_selected_form = update_selected_form;
  },
  UPDATE_SELECTED_FORM_FOR_LOOKUP(state: IFormState, update_selected_form_for_lookup: IForm) {
    state.update_selected_form_for_lookup = update_selected_form_for_lookup;
  },
  NEWLY_CREATED_FORM_FOR_EDIT_QR(state: IFormState, newly_created_form_for_edit_qr: IForm) {
    state.newly_created_form_for_edit_qr = newly_created_form_for_edit_qr;
  },
  UPDATE_PAGINATED_FORM_SUBMISSIONS(
    state: IFormState,
    paginated_form_submissions: IPaginatedData<ISearchableFormSubmission>
  ) {
    state.paginated_form_submissions = paginated_form_submissions;
  },
  SELECTED_FORM_SUMISSION_LINKS(
    state: IFormState,
    selected_form_submission_links: IFormSubmissionLinkResponse
  ) {
    state.selected_form_submission_links = selected_form_submission_links;
  }
};

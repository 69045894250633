import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IProfileState } from './types';

const state: IProfileState = {
  selected_profile: null,
  updated_selected_profile: null,
  update_selected_profile_image: null,
  is_email_submitted: false,
  updated_email: null,
  selected_casl_rules: null
};

// eslint-disable-next-line import/prefer-default-export
export const profile: Module<IProfileState, IRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

// eslint-disable-next-line import/order
import { MutationTree } from 'vuex';
import { IProduct } from '@/modules/product/product.interface';
import { IProductState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IProductState> = {
  UPDATE_PAGINATED_PRODUCTS(state: IProductState, paginated_products: IProduct[]) {
    state.paginated_products = paginated_products;
  },
  UPDATE_SELECTED_PRODUCT(state: IProductState, update_selected_product: IProduct) {
    state.update_selected_product = update_selected_product;
  }
};

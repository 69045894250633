import { EFilterOperators } from '@/modules/common/pagination.interface';
import { TApiDate, TFilter, TSearchBy, TSortBy } from '@/modules/common/pagination.type';
import { TSortDirection } from '@/modules/common/service.type';
import { EScanStats, EScanType } from '@/modules/scan/scan.enum';

export default class PaginationBuilder {
  private filters: TFilter[] = [];

  private page = 1;

  private pageSize = 20;

  private from: Date;

  private to: Date;

  private resource: string;

  private apiDate?: TApiDate;

  private search?: string;

  private searchBy?: TSearchBy[] = [];

  private sortBy?: TSortBy;

  private scanType?: EScanType;

  private stats?: EScanStats;

  public getSearchBy(): TSearchBy[] {
    return this.searchBy;
  }

  public addSearchBy(field: string, value: string | string[]): PaginationBuilder {
    this.searchBy.push({ field, value });
    this.searchBy = Array.from(new Set(this.searchBy.map((search) => search.value))).map((item) =>
      this.searchBy.find((search) => search.value === item)
    );
    return this;
  }

  public clearSearchBy(): void {
    this.searchBy = [];
  }

  public addFilter(
    field: string,
    operator: EFilterOperators,
    value: string | string[]
  ): PaginationBuilder {
    this.filters.push({ field, operator, value });
    return this;
  }

  public clearFilter(): void {
    this.filters = [];
  }

  public removeFilter(field: string): PaginationBuilder {
    this.filters = this.filters.filter((filter) => filter.field !== field);
    return this;
  }

  public getFilters(): TFilter[] {
    return this.filters;
  }

  public getPage(): number {
    return this.page;
  }

  public setPage(page: number): PaginationBuilder {
    this.page = page;
    return this;
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public setPageSize(pageSize: number): PaginationBuilder {
    this.pageSize = pageSize;
    return this;
  }

  public getFromDate(): Date {
    return this.from;
  }

  public setFromDate(from: Date): PaginationBuilder {
    this.from = from;
    return this;
  }

  public getToDate(): Date {
    return this.to;
  }

  public setToDate(to: Date): PaginationBuilder {
    this.to = to;
    return this;
  }

  public getApiDate(): TApiDate {
    return this.apiDate;
  }

  public setApiDate(from: Date, to: Date): PaginationBuilder {
    this.apiDate = { from, to };
    return this;
  }

  public getResource(): string {
    return this.resource;
  }

  public setResource(resource: string): PaginationBuilder {
    this.resource = resource;
    return this;
  }

  public getSearch(): string {
    return this.search;
  }

  public setSearch(search: string): PaginationBuilder {
    this.search = search;
    return this;
  }

  public getSortBy(): TSortBy {
    return this.sortBy;
  }

  public setSortBy(field: string, direction: TSortDirection = 'ASC') {
    this.sortBy = { field, direction };
    return this;
  }

  public getScanType(): EScanType {
    return this.scanType;
  }

  public setScanType(scanType: EScanType): PaginationBuilder {
    this.scanType = scanType;
    return this;
  }

  public getScanStats(): EScanStats {
    return this.stats;
  }

  public setScanStats(stats: EScanStats): PaginationBuilder {
    this.stats = stats;
    return this;
  }
}

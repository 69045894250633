import { IMetaDataField } from '@/modules/metadata/metadata.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import {
  IMetadataFieldBase,
  ISearchableMetadataField
} from '@/modules/metadata-admin/metadata.interface';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';
import PaginationBuilder from './common/PaginationBuilder';
import PaginatedResponse from './common/PaginatedResponse';

export default class Metadata {
  private readonly client: ClientInterface;

  private readonly resource: string = 'metadata';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  createMetadata(body: IMetadataFieldBase): Promise<ResourceResponse<IMetaDataField>> {
    return this.client.create<IMetadataFieldBase, IMetaDataField>(`${this.resource}/field`, body);
  }

  getMetadataById(id: string): Promise<ResourceResponse<IMetaDataField>> {
    return this.client.getById(`${this.resource}/field`, id);
  }

  updateMetadata(id: string, body: IMetadataFieldBase): Promise<ResourceResponse<IMetaDataField>> {
    return this.client.patch(`${this.resource}/field`, body, id);
  }

  deleteMetadataById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/field`, id);
  }

  getPaginatedMetadata(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableMetadataField>>> {
    builder.setResource(`${this.resource}/field`);
    return this.client.getPaginated<IPaginatedData<ISearchableMetadataField>>(builder);
  }
}

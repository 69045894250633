import { MutationTree } from 'vuex';
import { ILinkShortUrl, ISearchableLink } from '@/modules/qr-generator/link.interface';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import { IShortUrlState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IShortUrlState> = {
  UPDATE_PAGINATED_SHORT_URLS(
    state: IShortUrlState,
    paginated_short_urls: PaginatedResponse<ISearchableLink>
  ) {
    state.paginated_short_urls = paginated_short_urls;
  },
  SELECTED_SHORT_URL(state: IShortUrlState, selected_short_url: ILinkShortUrl) {
    state.selected_short_url = selected_short_url;
  },
  UPDATE_SHORT_URL(state: IShortUrlState, updated_short_url: ILinkShortUrl) {
    state.updated_short_url = updated_short_url;
  }
};

import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IUserCompany } from '@/modules/user/user.interface';
import { IProfileState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<IProfileState, IRootState> = {
  current_user_id(state: IProfileState): string {
    const getUserId: string = state.selected_profile?.id;
    return getUserId;
  },
  user_company_list(state: IProfileState): IUserCompany[] {
    const getCompanies: IUserCompany[] = state.selected_profile?.companies;
    return getCompanies;
  }
};

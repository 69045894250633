import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IEmailState } from './types';
import { mutations } from './mutations';
import { actions } from './actions';

const state: IEmailState = {
  is_email_exist: undefined
};

// eslint-disable-next-line import/prefer-default-export
export const email: Module<IEmailState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

/* eslint-disable import/order */
import APIError from '@/helpers/customize-error';
import { ERootActions, ERootMutations } from '@/modules/common/common.enum';
import { IRootState } from '@/modules/common/common.interface';
import { EErrorStatus } from '@/modules/error/error.enum';
import { IErrorResponse, IToastMessage } from '@/modules/error/error.interface';
import Api from '@/services/api/ApiFactory';
import { ActionContext, ActionTree } from 'vuex';
import router from '../router';

const ApiFactory = new Api();

const toastMessages: Record<EErrorStatus, IToastMessage> = {
  [EErrorStatus.BadRequest]: { message: '', color: 'error' },
  [EErrorStatus.Unauthorized]: { message: '', color: 'error' },
  [EErrorStatus.PaymentRequired]: { message: '', color: 'error' },
  [EErrorStatus.Forbidden]: { message: '', color: 'error' },
  [EErrorStatus.NotFound]: { message: '', color: 'error' },
  [EErrorStatus.InternalServerError]: {
    message: 'Oops! Something went wrong... Please try again later.',
    color: 'error'
  },
  [EErrorStatus.BadGateway]: {
    message: 'Oops! An error occurred... Please try again later.',
    color: 'error'
  },
  [EErrorStatus.ServiceUnavailable]: {
    message: 'Oops! An error occurred... Please try again in a minute.',
    color: 'error'
  },
  [EErrorStatus.GatewayTimeout]: {
    message: 'No internet connection: You seem to be offline. Please check your internet',
    color: 'error'
  }
};

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IRootState, IRootState> = {
  [ERootActions.THROW_CUSTOM_ERROR](
    { commit, dispatch, state }: ActionContext<IRootState, IRootState>,
    errorResponse
  ): Promise<unknown> {
    const error: IErrorResponse = errorResponse?.response?.data ?? errorResponse;
    const errorObj: IErrorResponse = {
      code: error.code,
      status: error.status,
      message: error.message
    };
    const errorMessage = errorObj.message
      ? errorObj.message.charAt(0).toUpperCase() + errorObj.message.slice(1)
      : '';
    let newError: APIError | undefined;
    let toastMessage: IToastMessage | undefined;

    if (error && error.status in toastMessages) {
      newError = new APIError(errorObj);
      toastMessage = { ...toastMessages[error.status] };
      if (toastMessage.message === '') {
        toastMessage.message = errorMessage;
      }
    } else {
      newError = new APIError(errorObj);
      toastMessage = { message: errorMessage, color: 'error' };
    }

    if (newError) {
      newError.status = error.status;
      newError.message = error.message;
    }
    if (error?.status !== EErrorStatus.Unauthorized) {
      commit(ERootMutations.SHOW_TOAST_MESSAGE, toastMessage);
    }
    commit(ERootMutations.SET_FRIENDLY_ERR_MSG, errorMessage);
    commit(ERootMutations.SET_LOADING, false);
    return new Promise((resolve, reject) => {
      if (error?.status === EErrorStatus.Unauthorized) {
        const isLoginRoute: boolean =
          router.currentRoute.value?.path === '/login' ||
          router.currentRoute.value?.path === '/login/canva';
        const maxRetries = 2;
        let isRefreshing = false;

        const refreshAuthToken = () => {
          isRefreshing = true;
          dispatch('authentication/REFRESH_AUTH_TOKEN')
            .then((response) => {
              isRefreshing = false;
              router.push('/');
              resolve(response);
            })
            .catch((refreshError) => {
              isRefreshing = false;
              console.error('Error during authentication refresh:', refreshError);
              reject(refreshError);
            });
        };

        if (state.refresh_retry_count === maxRetries || isLoginRoute) {
          dispatch(ERootActions.LOGOUT)
            .then(() => {
              router.push('/login');
            })
            .catch(() => {});
        } else if (state.refresh_retry_count < maxRetries && !isLoginRoute && !isRefreshing) {
          commit(ERootMutations.REFRESH_RETRY_COUNT, state.refresh_retry_count + 1);
          refreshAuthToken();
        }
      }
    });
  },
  [ERootActions.SET_LOADING]({ commit }, payload) {
    commit(ERootMutations.SET_LOADING, payload);
  },
  [ERootActions.GET_COMPANY_DETAILS]({ dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getCompany()
        .getCompanyDetails()
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  [ERootActions.LOGOUT]({ commit }) {
    commit(ERootMutations.CLEAR_ALL_STORAGE_DATA);
    commit(ERootMutations.SET_ERR, '');
    commit(ERootMutations.SET_FRIENDLY_ERR_MSG, '');
    commit(ERootMutations.SET_ABILITY, null);
    commit(ERootMutations.IS_FIRST_LOAD, true);
    commit(ERootMutations.REFRESH_RETRY_COUNT, 0);
    commit('account/SELECTED_ACCOUNT', null);
    commit('account/UPDATE_BILLING_DETAILS', null);
    commit('profile/SELECTED_CASL_RULES', null);
    commit('link/UPDATE_CONNECT_QR_STATS', null);
    localStorage.removeItem('defaultCompanyId');
  }
};

import { MutationTree } from 'vuex';
import {
  IImagePickerResult,
  IMediaFile,
  IMediaFolder,
  IMediaSearchItem,
  IMediaUpload
} from '@/modules/media-library/media.interface';
import { IMediaState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IMediaState> = {
  UPLOADED_MEDIA(state: IMediaState, uploaded_media: IMediaUpload) {
    state.uploaded_media = uploaded_media;
  },
  UPDATE_FILE(state: IMediaState, update_file: IMediaFile) {
    state.update_file = update_file;
  },
  UPDATE_FOLDER(state: IMediaState, update_folder: IMediaFolder) {
    state.update_folder = update_folder;
  },
  CURRENT_FOLDER(state: IMediaState, current_folder: IMediaFolder) {
    state.current_folder = current_folder;
  },
  ADD_NEW_MEDIA(state: IMediaState, new_added_media: IMediaFolder | IMediaFile) {
    state.new_added_media.push(new_added_media);
  },
  CLEAR_NEW_ADDED_MEDIA(state: IMediaState) {
    state.new_added_media = [];
    state.remove_media_file = [];
  },
  REMOVE_MEDIA_FILE(state: IMediaState, remove_media_file: string) {
    state.remove_media_file.push(remove_media_file);
  },
  UPDATE_SEARCH_MEDIA_FILE_AND_FOLDER(
    state: IMediaState,
    update_search_media_file_and_folder: IMediaSearchItem
  ) {
    state.update_search_media_file_and_folder = update_search_media_file_and_folder;
  },
  UPDATE_MEDIA_FOLDER(state: IMediaState, update_media_folder: IMediaSearchItem) {
    state.update_media_folder = update_media_folder;
  },
  SELECTED_MEDIA(state: IMediaState, selected_media: IImagePickerResult) {
    state.selected_media = selected_media;
  }
};

import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import { IEmailChecker } from '@/modules/email/email.interface';
import { IEmailState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IEmailState, IRootState> = {
  CHECK_EMAIL(
    { commit, dispatch }: ActionContext<IEmailState, IRootState>,
    payload: string
  ): Promise<IEmailChecker> {
    return new Promise((resolve, reject) => {
      ApiFactory.getEmail()
        .checkEmail(payload)
        .then((response: IEmailChecker) => {
          commit('GET_EMAIL_EXIST', response.isExist);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

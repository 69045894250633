import { IPaginatedData } from '@/modules/common/pagination.interface';
import { ETechBatchType } from '@/modules/tech-batch/tech-batch.enum';
import {
  ICreateProtectCodePlusBatch,
  ICreateQrBatch,
  IResPresignedUrlZip,
  ITechBatchProtectCodePlus,
  ITechBatchQr,
  IUpdateProtectCodePlusBatch,
  IUpdateQrBatch,
  TTechBatchAction
} from '@/modules/tech-batch/tech-batch.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class TechBatch {
  private readonly client: ClientInterface;

  private readonly type: ETechBatchType;

  private resource: string;

  constructor(client: ClientInterface, type: ETechBatchType) {
    this.client = client;
    this.type = type;
    this.resource = `tech-batch/${this.type}`;
  }

  getPaginatedTech<T>(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<T>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<T>>(builder);
  }

  getTechById<T>(id: string): Promise<ResourceResponse<T | null>> {
    return this.client.getById(this.resource, id);
  }

  createTechBatch(body: ICreateQrBatch): Promise<ResourceResponse<ITechBatchQr>> {
    return this.client.create<ICreateQrBatch, ITechBatchQr>(this.resource, body);
  }

  updateTechBatch(id: string, body: IUpdateQrBatch): Promise<ResourceResponse<ITechBatchQr>> {
    return this.client.patch(this.resource, body, id);
  }

  deleteTechBatchById(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }

  getQrBatchDownloadUrlByAction(
    id: string,
    action: TTechBatchAction
  ): Promise<ResourceResponse<IResPresignedUrlZip>> {
    return this.client.get(`${this.resource}/${id}/download/${action}`);
  }

  createProtectCodePlusBatch(
    body: ICreateProtectCodePlusBatch
  ): Promise<ResourceResponse<ITechBatchProtectCodePlus>> {
    return this.client.create<ICreateProtectCodePlusBatch, ITechBatchProtectCodePlus>(
      this.resource,
      body
    );
  }

  updateTechBatchProtectCodePlus(
    id: string,
    body: IUpdateProtectCodePlusBatch
  ): Promise<ResourceResponse<ITechBatchProtectCodePlus>> {
    return this.client.patch(this.resource, body, id);
  }
}

import { ActionContext, ActionTree } from 'vuex';
import Api from '@/services/api/ApiFactory';
import { IRootState } from '@/modules/common/common.interface';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import {
  INotification,
  INotificationResponse
} from '@/modules/notification/notification.interface';
import { INotificationState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<INotificationState, IRootState> = {
  GET_ALL_NOTIFICATIONS(
    { commit, dispatch }: ActionContext<INotificationState, IRootState>,
    unread: boolean
  ): Promise<INotificationResponse> {
    return new Promise((resolve, reject) => {
      ApiFactory.getNotifications()
        .getAllNotifications(unread)
        .then((response: INotificationResponse) => {
          commit('UPDATE_NOTIFICATIONS', response.data);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  MARK_SELECTED_NOTIFICATION_AS_READ(
    { commit, dispatch }: ActionContext<INotificationState, IRootState>,
    notificationId: string
  ): Promise<ResourceResponse<INotification>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getNotifications()
        .markNotificationAsRead(notificationId)
        .then((response: ResourceResponse<INotification>) => {
          commit('UPDATE_SELECTED_NOTIFICATION', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  MARK_ALL_NOTIFICATIONS_AS_READ({
    dispatch
  }: ActionContext<INotificationState, IRootState>): Promise<ResourceResponse<boolean>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getNotifications()
        .markAllNotificationsAsRead()
        .then((response: ResourceResponse<boolean>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
// eslint-disable-next-line import/order
import { ActionTree } from 'vuex';
import { AudienceState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<AudienceState, IRootState> = {
  GET_PAGINATED_AUDIENCE_LIST({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      builder.setSearch(payload.search ? payload.search : '');
      ApiFactory.getAudience()
        .getPaginatedAudience(builder)
        .then((response) => {
          commit('UPDATE_PAGINATED_AUDIENCE_LIST', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  EXPORT_AUDIENCE_LIST({ dispatch }, search) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAudience()
        .getAudienceExport(search)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};

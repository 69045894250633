import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { ITechBatchState } from './types';
import { actions } from './actions';
import { mutations } from './mutations';

const state: ITechBatchState = {
  paginated_tech_batch: null,
  selected_tech_batch: null,
  update_selected_tech_batch: null,
  paginated_protectcode_plus_tech_batch: null,
  selected_protectcode_plus_batch: null
};
// eslint-disable-next-line import/prefer-default-export
export const techbatch: Module<ITechBatchState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { ISignUpState } from './types';
import { mutations } from './mutations';
import { actions } from './actions';

const state: ISignUpState = {
  is_signed_up: false
};

// eslint-disable-next-line import/prefer-default-export
export const signup: Module<ISignUpState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

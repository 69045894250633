import { IEmailChecker } from '@/modules/email/email.interface';
import ClientInterface from './common/ClientInterface';

export default class Email {
  private readonly client: ClientInterface;

  private readonly resource = 'email';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  checkEmail(email: string): Promise<IEmailChecker> {
    return this.client.get(`${this.resource}/check/${email}`);
  }
}

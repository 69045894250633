import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IMetadataState } from './types';
import { actions } from './actions';
import { mutations } from './mutations';

const state: IMetadataState = {
  selected_metadata: null,
  update_selected_metadata: null,
  paginated_metadata: null
};

// eslint-disable-next-line import/prefer-default-export
export const metadata: Module<IMetadataState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { ILinkState } from './types';

const state: ILinkState = {
  paginated_links: null,
  selected_connect_qr: null,
  updated_connect_qr: null,
  selected_static_qr: null,
  updated_static_qr: null,
  selected_link: null,
  update_selected_link: null,
  update_scanned_at: null,
  update_scanned_at_by_metrics: null,
  update_scanned_at_by_location: null,
  update_scanned_at_by_location_countries: null,
  update_scan_time_series: null,
  update_popular_scans_by_dimensions: null,
  update_scan_top_links: null,
  update_connect_qr_stats: null,
  update_connect_qr_allowance: null,
  update_tags: null,
  newly_created_qr: null,
  selected_ai_qr: [],
  latest_ai_qr: null,
  update_qr_ratio_value: null,
  update_qr_aspect_ratio_value: null,
  update_qr_position_value: null,
  back_to_done: false,
  ai_qr_error: null
};

// eslint-disable-next-line import/prefer-default-export
export const link: Module<ILinkState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

/**
 * Check if the input is valid Spotify urls
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isSpotifyUrl = (url: string): boolean => {
  const spotifyUrlRegex = /^(https?:\/\/)?(open|play)\.spotify\.com\/?/;
  return spotifyUrlRegex.test(url);
};

/**
 * Check if the input is valid Facebook url
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isFacebookUrl = (url: string): boolean => {
  const isProfileURLRegex = /^(https?:\/\/)?(www\.)?facebook\.com(\/.*)?$/;

  return isProfileURLRegex.test(url);
};

/**
 * Check if the input is valid LinkedIn url
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isLinkedInURL = (url: string): boolean => {
  const isProfileURLRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;

  return isProfileURLRegex.test(url);
};

/**
 * Check if the input is valid Twitter url
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isTwitterURL = (url: string): boolean => {
  const isProfileURLRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/.*$/;

  return isProfileURLRegex.test(url);
};

/**
 * Check if the input is valid Instagram url
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isInstagramUrl = (url: string): boolean => {
  const isProfileURLRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/.*$/;

  return isProfileURLRegex.test(url);
};

/**
 * Check if the input is valid Youtube url
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isYouTubeUrl = (url: string): boolean => {
  const isYouTubeUrlRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com(\/.*)?|youtu\.be\/.*)$/;

  return isYouTubeUrlRegex.test(url);
};

/**
 * Check if the input is valid TikTok url
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isTikTokUrl = (url: string): boolean => {
  const tikTokUrlRegex =
    /^(https?:\/\/)?(www\.)?(tiktok\.com\/|t\.tiktok\.com\/)/;

  return tikTokUrlRegex.test(url);
};

/**
 * Check if the input is valid WeChat url
 *
 * @param {string} url
 * @returns {boolean}
 */
export function isWeChatUrl(url: string): boolean {
  const weChatUrlRegex = /^(https?:\/\/)?(www\.)?(wechat\.com|weixin\.qq\.com)/;
  return weChatUrlRegex.test(url);
}

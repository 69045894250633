import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import {
  ICampaign,
  ICreateCampaignParams,
  ISearchableCampaign,
  IUpdateCampaignParams
} from '@/modules/campaign/campaign.interface';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { ICampaignState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ICampaignState, IRootState> = {
  GET_PAGINATED_CAMPAIGN(
    { commit, dispatch }: ActionContext<ICampaignState, IRootState>,
    payload
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getCampaign()
        .getPaginatedCampaigns(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableCampaign>>) => {
          commit('UPDATE_PAGINATED_CAMPAIGN', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_CAMPAIGN_BY_ID(
    { commit, dispatch }: ActionContext<ICampaignState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getCampaign()
        .getCampaignById(id)
        .then((response: ResourceResponse<ICampaign | null>) => {
          commit('SELECTED_CAMPAIGN', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_CAMPAIGN(
    { commit, dispatch }: ActionContext<ICampaignState, IRootState>,
    payload: ICreateCampaignParams
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getCampaign()
        .createCampaignById(payload)
        .then((response: ResourceResponse<ICampaign>) => {
          const getCampaignPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          commit('SELECTED_CAMPAIGN', response.resource);
          dispatch('GET_PAGINATED_CAMPAIGN', getCampaignPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_CAMPAIGN(
    { commit, dispatch }: ActionContext<ICampaignState, IRootState>,
    payload: { id: string; body: IUpdateCampaignParams }
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getCampaign()
        .updateCampaign(payload.id, payload.body)
        .then((response: ResourceResponse<ICampaign>) => {
          const getTechBatchPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          commit('UPDATE_SELECTED_CAMPAIGN', response.resource);
          dispatch('GET_PAGINATED_CAMPAIGN', getTechBatchPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_CAMPAIGN({ dispatch }: ActionContext<ICampaignState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getCampaign()
        .deleteCampaignById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

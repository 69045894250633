export enum ELinkType {
  URL = 'url',
  EMAIL = 'email',
  PHONE = 'phone',
  SMS = 'sms',
  CONTACT = 'contact',
  BUSINESS_CARD = 'business_card',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  WECHAT = 'wechat',
  LOCATION = 'location',
  TEXT = 'text',
  APP = 'app',
  LINKEDIN = 'linkedin',
  SPOTIFY = 'spotify',
  WIFI = 'wifi',
  FORM = 'form'
}

export enum EQrPatternShape {
  DOTS = 'dots',
  ROUNDED = 'rounded',
  CLASSY = 'classy',
  CLASSY_ROUNDED = 'classy_rounded',
  SQUARE = 'square',
  EXTRA_ROUNDED = 'extra_rounded'
}

export enum EQrCornerShape {
  SQUARE_AND_SQUARE = 'square_and_square',
  SQUARE_AND_DOT = 'square_and_dot',
  ROUNDED_AND_SQUARE = 'rounded_and_square',
  ROUNDED_AND_DOT = 'rounded_and_dot',
  CIRCLE_AND_SQUARE = 'circle_and_square',
  CIRCLE_AND_DOT = 'circle_and_dot'
}

export enum ELinkContactSocial {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  WECHAT = 'weChat',
  LINKEDIN = 'linkedIn',
  SPOTIFY = 'spotify',
  TIKTOK = 'tiktok',
  YOUTUBE = 'youtube'
}

export enum ELinkContactTemplate {
  CONNECTCARD = 'connect_card',
  EASYCARD = 'easy_card',
  SWIFTCARD = 'swift_card',
  QUICKCARD = 'quick_card',
  RAPIDCARD = 'rapid_card'
}

export enum ERootActions {
  UPDATE_AUTH = 'UPDATE_AUTH',
  THROW_CUSTOM_ERROR = 'THROW_CUSTOM_ERROR',
  SET_LOADING = 'SET_LOADING',
  LOGOUT = 'LOGOUT',
  GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS'
}
export enum ERootMutations {
  CLEAR_ALL_STORAGE_DATA = 'CLEAR_ALL_STORAGE_DATA',
  SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE',
  TOGGLE_SIDE_NAVBAR = 'TOGGLE_SIDE_NAVBAR',
  SET_TOGGLE_SIDE_NAVBAR = 'SET_TOGGLE_SIDE_NAVBAR',
  SET_LOADING = 'SET_LOADING',
  SHOW_SIDEBAR_POPUP = 'SHOW_SIDEBAR_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  UPDATE_AUTH = 'UPDATE_AUTH',
  SHOW_QUICK_ADD_POPUP = 'SHOW_QUICK_ADD_POPUP',
  HIDE_QUICK_ADD_POPUP = 'HIDE_QUICK_ADD_POPUP',
  SHOW_EDIT_POPUP = 'SHOW_EDIT_POPUP',
  SHOW_QUICK_VIEW_POPUP = 'SHOW_QUICK_VIEW_POPUP',
  SET_ABILITY = 'SET_ABILITY',
  SET_AUTH_STATUS_SUCCESS = 'SET_AUTH_STATUS_SUCCESS',
  SET_AUTH_STATUS_SUCCESS_BUT_NO_ROLE = 'SET_AUTH_STATUS_SUCCESS_BUT_NO_ROLE',
  SET_AUTH_STATUS_ERR = 'SET_AUTH_STATUS_ERR',
  RESET_AUTH_STATUS = 'RESET_AUTH_STATUS',
  SET_AUTH_STATUS_LOADING = 'SET_AUTH_STATUS_LOADING',
  SET_ERR = 'SET_ERR',
  SET_FRIENDLY_ERR_MSG = 'SET_FRIENDLY_ERR_MSG',
  GET_DATE = 'GET_DATE',
  GET_HOST_NAME = 'GET_HOST_NAME',
  IS_REFRESHED_TOKEN = 'IS_REFRESHED_TOKEN',
  IS_FIRST_LOAD = 'IS_FIRST_LOAD',
  REFRESH_RETRY_COUNT = 'REFRESH_RETRY_COUNT'
}

export enum EDateFormat {
  YYYYMMDD = 'YYYYMMDD',
  YYYY_MM_DD = 'YYYY-MM-DD',
  YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm'
}

export enum EGranularity {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export enum EScanFilterSubject {
  BROWSER = 'browser',
  CAMPAIGN = 'campaign',
  COUNTRY = 'country',
  DEVICE_BRAND = 'deviceBrand',
  LOCATION = 'location',
  PRODUCT = 'product',
  SEGMENT = 'segment',
  QR_BATCH = 'qrBatch'
}

export enum EFilterCondition {
  IN = 'in',
  NOT_IN = 'notIn',
  GTE = 'gte',
  LTE = 'lte'
}

export enum EChipStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  ERROR = 'error',
  GENERATING = 'generating',
  READY = 'ready',
  RECEIVED = 'received',
  DRAFT = 'draft',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  IDLE = 'idle',
  UPDATING = 'updating',
  TO_CUSTOMER = 'in transit to customer',
  TO_SITE = 'in transit to site',
  IN_TRANSIT = 'in transit',
  READY_TO_SEND = 'ready to send'
}

export enum EColor {
  SHEEN_GREEN = '#82C341',
  SHEEN_GREEN_DARKEN_1 = '#70A838',
  HONEY_YELLOW = '#FDBC11',
  TANGERINE_RED = '#F05C2B',
  TANGERINE_RED_LIGHTEN_1 = '#FFA583',
  DARK_GRAY = '#3A3A3A',
  NEGATIVE_RED = '#D72D18',
  NEGATIVE_RED_DARKEN_1 = '#AC2413',
  ROYAL_BLUE = '#4168C5',
  TEAL_BLUE = '#56A799',
  STEEL_BLUE = '#4798C2',
  ROYAL_PURPLE = '#8A2294',
  CHEDDAR_ORANGE = '#EF9D37'
}

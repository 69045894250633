import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { ICreateProductApi, IProduct } from '@/modules/product/product.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { EFilterOperators } from '@/modules/common/pagination.interface';
import { IProductState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IProductState, IRootState> = {
  GET_PAGINATED_PRODUCTS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getProductsV2()
        .getPaginatedProducts(builder)
        .then((response: PaginatedResponse<IProduct[]>) => {
          commit('UPDATE_PAGINATED_PRODUCTS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_PRODUCT_BY_ID({ dispatch, commit }: ActionContext<IProductState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProductsV2()
        .getProductById(id)
        .then((response: ResourceResponse<IProduct | null>) => {
          commit('UPDATE_SELECTED_PRODUCT', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_PRODUCT(
    { dispatch, commit }: ActionContext<IProductState, IRootState>,
    payload: ICreateProductApi
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProductsV2()
        .createProduct(payload)
        .then((response: ResourceResponse<IProduct>) => {
          commit('UPDATE_SELECTED_PRODUCT', response.resource);
          const getProductPayload = {
            page: 1,
            itemsPerPage: 15
          };
          dispatch('GET_PAGINATED_PRODUCTS', getProductPayload);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPDATE_SELECTED_PRODUCT({ dispatch, commit }: ActionContext<IProductState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProductsV2()
        .updateProduct(payload.id, payload.body)
        .then((response: ResourceResponse<IProduct>) => {
          commit('UPDATE_SELECTED_PRODUCT', response.resource);
          const getProductPayload = {
            page: 1,
            itemsPerPage: 15
          };
          dispatch('GET_PAGINATED_PRODUCTS', getProductPayload);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_PRODUCT({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProductsV2()
        .deleteProductById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IContainerContentItemInfo } from '@/modules/tracktrace/container-content';
import { ITrackTraceState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<ITrackTraceState, IRootState> = {
  locked_by_user_id(state: ITrackTraceState): string {
    const getLockedByUserId: string = state.selected_container?.lockedBy;
    return getLockedByUserId;
  },
  update_container_content(state: ITrackTraceState): IContainerContentItemInfo[] {
    return state.update_container_transaction_content?.content;
  }
};

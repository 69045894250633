// eslint-disable-next-line import/order
import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IScansList } from '@/modules/common/location.interface';
import { IReportList, IReportScanList } from '@/modules/reports/report.interface';
import { ReportState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<ReportState, IRootState> = {
  paginated_report_list(state): IReportList {
    return state.paginated_report_list;
  },
  report_scan_locations(state): IScansList {
    return state.report_scan_locations;
  },
  report_scan_records(state): IReportScanList {
    return state.report_scan_records;
  }
};

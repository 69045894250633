/* eslint-disable import/prefer-default-export */
import { MutationTree } from 'vuex';
import { GeographyState } from './types';

export const mutations: MutationTree<GeographyState> = {
  UPDATE_SCANS_BY_LOCATION(state, scans_by_location) {
    state.scans_by_location = scans_by_location;
  },
  UPDATE_SCANS_BY_DATE(state, scans_by_date) {
    state.scans_by_date = scans_by_date;
  }
};

import { IScansList } from '@/modules/common/location.interface';
import { IReportList, IReportScanList } from '@/modules/reports/report.interface';
import ClientInterface from './common/ClientInterface';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';
import PaginatedResponse from './common/PaginatedResponse';

export default class Report {
  private readonly client: ClientInterface;

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedReports(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<PaginatedResponse<IReportList>> {
    builder.setResource('analytics/report');
    return this.client.getPaginated<IReportList>(builder);
  }

  getPaginatedReportScans(
    reportId: number | string,
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<PaginatedResponse<IReportScanList>> {
    builder.setResource(`analytics/report/${reportId}/scans`);
    return this.client.getPaginated<IReportScanList>(builder);
  }

  getReportScanLocations(
    reportId: number | string,
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScansList>> {
    builder.setResource(`analytics/report/${reportId}/locations`);
    return this.client.getScansByGeohash<IScansList>(builder);
  }
}

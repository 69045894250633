import { EGranularity } from '@/modules/common/common.enum';
import { TPayload } from '@/modules/common/common.type';
import { ITimeSeries } from '@/modules/common/service.type';
import ClientInterface from '@/services/api/common/ClientInterface';

export default class TimeSeries {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/time-series';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getDashboardTimeSeriesData(payload: TPayload<EGranularity>): Promise<ITimeSeries> {
    return this.client.get<ITimeSeries>(
      `v1/${this.resource}?granularity=${payload.granularity}&from=${payload.from}&to=${payload.to}`
    );
  }
}

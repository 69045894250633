// eslint-disable-next-line import/order
import { GetterTree } from 'vuex';
import { IScansByDateList } from '@/modules/analytics/analytics/analytics.interface';
import { IRootState } from '@/modules/common/common.interface';
import { IScansList } from '@/modules/common/location.interface';
import { GeographyState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<GeographyState, IRootState> = {
  scans_by_location(state): IScansList {
    return state.scans_by_location;
  },
  scans_by_date(state): IScansByDateList {
    return state.scans_by_date;
  }
};

import { IPaginatedData } from '@/modules/common/pagination.interface';
import { EScanType } from '@/modules/scan/scan.enum';
import { IScan } from '@connect-saas/types';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';

export default class Scan {
  private readonly client: ClientInterface;

  private readonly resource: string = 'scan';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getScansByDate(
    builder: PaginationBuilder = new PaginationBuilder(),
    scanType: EScanType = EScanType.CAMPAIGN
  ): Promise<PaginatedResponse<IPaginatedData<IScan>>> {
    builder.setResource(this.resource);
    builder.setScanType(scanType);
    return this.client.getPaginated<IPaginatedData<IScan>>(builder);
  }
}

import { IScansList } from '@/modules/common/location.interface';
import { EScanType } from '@/modules/scan/scan.enum';
import ClientInterface from './common/ClientInterface';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';

export default class ScansByLocations {
  private readonly client: ClientInterface;

  private readonly resource: string = 'analytics/scan/locations';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getGeohashScansByLocation(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScansList>> {
    builder.setResource(this.resource);
    builder.setScanType(EScanType.CAMPAIGN);
    return this.client.getScansByGeohash<IScansList>(builder);
  }
}

import {
  ELinkContactTemplate,
  ELinkType,
  EQrCornerShape,
  EQrPatternShape
} from '@/modules/qr-generator/link-config.enum';
import { computed } from 'vue';

export const titleData = {
  all: 'scans + clicks',
  connect_qr: 'scans',
  short_url: 'clicks'
};
export const typeData = {
  connect_qr: {
    title: 'ConnectQR Codes',
    topPerforming: 'Top performing ConnectQR Codes',
    routeName: 'QR',
    routePath: 'qr'
  },
  short_url: {
    title: 'Shortened URLs',
    topPerforming: 'Top performing Short URLs',
    routeName: 'URL',
    routePath: 'url-shortener'
  }
};
export const filterTypeList = computed(() => {
  const result = Object.keys(typeData).map((item) => {
    const { title } = typeData[item];
    return {
      title,
      value: item
    };
  });
  result.unshift({
    title: 'All',
    value: 'all'
  });
  return result;
});
export const allAnalticsType = computed(() => Object.keys(typeData).map((item) => item));

export const analyticsData = {
  stats: {
    dimensions: null,
    action: 'link/GET_SCANNED_AT_BY_METRICS',
    rule: 'LinkAnalyticsByMetric',
    showDetailsPage: true
  },
  location: {
    dimensions: null,
    action: 'link/GET_SCANNED_AT_BY_LOCATION_COUNTRIES',
    rule: 'LinkAnalyticsScanLocations',
    showDetailsPage: true
  },
  topPerforming: {
    dimensions: null,
    action: 'link/GET_SCAN_TOP_LINKS_SETTING',
    rule: 'LinkAnalyticsTopLinks',
    showDetailsPage: false
  },
  performanceOvertime: {
    dimensions: null,
    action: 'link/GET_SCAN_TIME_SERIES',
    rule: 'LinkAnalyticsScansOverTime',
    showDetailsPage: true
  },
  topDay: {
    dimensions: null,
    action: 'link/GET_SCANNED_AT',
    rule: 'LinkAnalyticsScannedAt',
    showDetailsPage: true
  },
  topTime: {
    metrdimensionsic: null,
    action: 'link/GET_SCANNED_AT',
    rule: 'LinkAnalyticsScannedAt',
    showDetailsPage: true
  },
  deviceBrowser: {
    dimensions: 'deviceBrowser',
    action: 'link/GET_POPULAR_SCANS_BY_DIMENSIONS',
    rule: 'LinkAnalyticsByDimension',
    showDetailsPage: true
  },
  deviceOs: {
    dimensions: 'deviceOs',
    action: 'link/GET_POPULAR_SCANS_BY_DIMENSIONS',
    rule: 'LinkAnalyticsByDimension',
    showDetailsPage: true
  },
  totalClicks: {
    dimensions: null,
    action: 'link/GET_SCANNED_AT_BY_METRICS',
    rule: 'LinkAnalyticsByMetric',
    showDetailsPage: true
  },
  referrerDomain: {
    dimensions: 'referrerDomain',
    action: 'link/GET_POPULAR_SCANS_BY_DIMENSIONS',
    rule: 'LinkAnalyticsByDimension',
    showDetailsPage: true
  }
};

export const previewInfo = {
  scansByMetics: {
    scans: 360,
    visitors: 26,
    locationCountries: 2
  },
  scanLocations: [
    {
      location: {
        lat: 13.359375,
        lng: 100.546875
      },
      count: 50
    },
    {
      location: {
        lat: 51.328125,
        lng: -0.703125
      },
      count: 37
    }
  ],
  countryStats: [
    {
      name: 'TH',
      scans: 50
    },
    {
      name: 'GB',
      scans: 37
    }
  ],
  topPerforming: {
    connect_qr: {
      type: 'connect_qr',
      data: [
        {
          link: {
            id: 'id-001',
            accountId: 'accountid-001',
            companyId: 'companyid-001',
            config: {
              type: ELinkType.LOCATION,
              latitude: 10,
              longitude: 10
            },
            name: 'Preview 1',
            qrConfig: {
              cornerShape: EQrCornerShape.CIRCLE_AND_DOT,
              patternColor: '#000000',
              cornerColor: '#000000',
              patternShape: EQrPatternShape.SQUARE
            },
            configType: 'location',
            configTypeText: 'Location',
            type: 'connect_qr',
            domain: 'qr.ypb.io',
            code: 'preview-001',
            url: 'https://qr.ypb.io/preview-001',
            qrData: 'https://qr.ypb.io/preview-001',
            qr: {
              config: {
                cornerShape: EQrCornerShape.CIRCLE_AND_DOT,
                patternColor: '#000000',
                cornerColor: '#000000',
                patternShape: EQrPatternShape.SQUARE
              },
              data: 'https://qr.ypb.io/preview-001'
            },
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            createdBy: 'preview-001',
            updatedAt: new Date('2024-02-26T07:11:08.051Z'),
            updatedBy: 'preview-001',
            deletedAt: new Date('2024-02-09T04:21:46.056Z'),
            deletedBy: 'preview-001'
          },
          scans: 96
        },
        {
          link: {
            id: 'id-002',
            accountId: 'accountid-002',
            companyId: 'companyid-002',
            config: {
              template: ELinkContactTemplate.EASYCARD,
              firstName: 'John',
              lastName: 'Doe',
              templateColor: '#70A838',
              type: ELinkType.BUSINESS_CARD,
              work: {}
            },
            name: 'Preview 2',
            qrConfig: {
              cornerShape: EQrCornerShape.SQUARE_AND_SQUARE,
              patternColor: '#000000',
              cornerColor: '#000000',
              patternShape: EQrPatternShape.ROUNDED
            },
            configType: 'business_card',
            configTypeText: 'Business Card',
            type: 'connect_qr',
            domain: 'qr.ypb.io',
            code: 'preview-002',
            url: 'https://qr.ypb.io/preview-002',
            qrData: 'https://qr.ypb.io/preview-002',
            qr: {
              config: {
                cornerShape: EQrCornerShape.SQUARE_AND_SQUARE,
                patternColor: '#000000',
                cornerColor: '#000000',
                patternShape: EQrPatternShape.ROUNDED
              },
              data: 'https://qr.ypb.io/preview-002'
            },
            createdAt: new Date('2024-01-15T10:12:32.608Z'),
            createdBy: 'preview-002',
            updatedAt: new Date('2024-02-26T07:09:08.013Z'),
            updatedBy: 'preview-002'
          },
          scans: 46
        },
        {
          link: {
            id: 'id-003',
            accountId: 'accountId-003',
            companyId: 'companyId-003',
            config: {
              url: 'https://connectqr.ai',
              type: ELinkType.URL
            },
            name: 'Preview 3',
            qrConfig: {
              cornerShape: EQrCornerShape.SQUARE_AND_DOT,
              patternColor: '#000000',
              cornerColor: '#000000',
              patternShape: EQrPatternShape.SQUARE
            },
            configType: 'url',
            configTypeText: 'Url',
            type: 'connect_qr',
            domain: 'qr.ypb.io',
            code: 'preview-003',
            url: 'https://qr.ypb.io/preview-003',
            qrData: 'https://qr.ypb.io/preview-003',
            qr: {
              config: {
                cornerShape: EQrCornerShape.SQUARE_AND_DOT,
                patternColor: '#000000',
                cornerColor: '#000000',
                patternShape: EQrPatternShape.SQUARE
              },
              data: 'https://qr.ypb.io/preview-003'
            },
            createdAt: new Date('2024-01-11T03:25:36.641Z'),
            createdBy: 'preview-003',
            updatedAt: new Date('2024-02-26T07:11:01.368Z'),
            updatedBy: 'preview-003'
          },
          scans: 19
        },
        {
          link: {
            id: 'id-004',
            accountId: 'accountid-004',
            companyId: 'companyid-004',
            config: {
              type: ELinkType.LOCATION,
              latitude: 0,
              longitude: 0
            },
            name: 'Preview 4',
            qrConfig: {
              cornerShape: EQrCornerShape.CIRCLE_AND_DOT,
              patternColor: '#000000',
              cornerColor: '#000000',
              patternShape: EQrPatternShape.SQUARE
            },
            configType: 'location',
            configTypeText: 'Location',
            type: 'connect_qr',
            domain: 'qr.ypb.io',
            code: 'preview-004',
            url: 'https://qr.ypb.io/preview-004',
            qrData: 'https://qr.ypb.io/preview-004',
            qr: {
              config: {
                cornerShape: EQrCornerShape.CIRCLE_AND_DOT,
                patternColor: '#000000',
                cornerColor: '#000000',
                patternShape: EQrPatternShape.SQUARE
              },
              data: 'https://qr.ypb.io/preview-004'
            },
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            createdBy: 'preview-004',
            updatedAt: new Date('2024-02-26T07:11:08.051Z'),
            updatedBy: 'preview-004',
            deletedAt: new Date('2024-02-09T04:21:46.056Z'),
            deletedBy: 'preview-004'
          },
          scans: 20
        },
        {
          link: {
            id: 'id-005',
            accountId: 'accountId-005',
            companyId: 'companyId-005',
            config: {
              url: 'https://connectqr.ai',
              type: ELinkType.URL
            },
            name: 'Preview 5',
            qrConfig: {
              cornerShape: EQrCornerShape.SQUARE_AND_DOT,
              patternColor: '#000000',
              cornerColor: '#000000',
              patternShape: EQrPatternShape.SQUARE
            },
            configType: 'url',
            configTypeText: 'Url',
            type: 'connect_qr',
            domain: 'qr.ypb.io',
            code: 'preview-005',
            url: 'https://qr.ypb.io/preview-005',
            qrData: 'https://qr.ypb.io/preview-005',
            qr: {
              config: {
                cornerShape: EQrCornerShape.SQUARE_AND_DOT,
                patternColor: '#000000',
                cornerColor: '#000000',
                patternShape: EQrPatternShape.SQUARE
              },
              data: 'https://qr.ypb.io/preview-005'
            },
            createdAt: new Date('2024-01-11T03:25:36.641Z'),
            createdBy: 'preview-005',
            updatedAt: new Date('2024-02-26T07:11:01.368Z'),
            updatedBy: 'preview-005'
          },
          scans: 200
        }
      ]
    },
    short_url: {
      type: 'short_url',
      data: [
        {
          link: {
            code: 'preview-001',
            updatedBy: 'preview-001',
            type: 'short_url',
            isActive: true,
            url: 'https://s.ypb.io/preview-001',
            tags: ['Preview'],
            accountId: 'accountid-001',
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            companyId: 'companyid-001',
            scans: 3,
            createdBy: 'preview-001',
            domain: 's.ypb.io',
            name: 'Preview 1',
            id: 'id-001',
            config: {
              redirectTo: 'connectqr.ai'
            },
            updatedAt: new Date('2024-02-07T15:38:41.466Z')
          },
          scans: 80
        },
        {
          link: {
            code: 'preview-002',
            updatedBy: 'preview-002',
            type: 'short_url',
            isActive: true,
            url: 'https://s.ypb.io/preview-002',
            tags: [],
            accountId: 'accountid-002',
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            companyId: 'companyid-002',
            scans: 1,
            createdBy: 'preview-002',
            domain: 's.ypb.io',
            name: 'Preview 2',
            id: 'id-002',
            config: {
              redirectTo: 'connectqr.ai'
            },
            updatedAt: new Date('2024-02-07T15:38:41.466Z')
          },
          scans: 29
        },
        {
          link: {
            code: 'preview-003',
            updatedBy: 'preview-003',
            type: 'short_url',
            isActive: true,
            url: 'https://s.ypb.io/preview-003',
            tags: [],
            accountId: 'accountid-003',
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            companyId: 'companyid-003',
            scans: 1,
            createdBy: 'preview-003',
            domain: 's.ypb.io',
            name: 'Preview 3',
            id: 'id-003',
            config: {
              redirectTo: 'connectqr.ai'
            },
            updatedAt: new Date('2024-02-07T15:38:41.466Z')
          },
          scans: 13
        },
        {
          link: {
            code: 'preview-004',
            updatedBy: 'preview-004',
            type: 'short_url',
            isActive: true,
            url: 'https://s.ypb.io/preview-004',
            tags: [],
            accountId: 'accountid-004',
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            companyId: 'companyid-004',
            scans: 1,
            createdBy: 'preview-004',
            domain: 's.ypb.io',
            name: 'Preview 4',
            id: 'id-004',
            config: {
              redirectTo: 'connectqr.ai'
            },
            updatedAt: new Date('2024-02-07T15:38:41.466Z')
          },
          scans: 8
        },
        {
          link: {
            code: 'preview-005',
            updatedBy: 'preview-005',
            type: 'short_url',
            isActive: true,
            url: 'https://s.ypb.io/preview-005',
            tags: [],
            accountId: 'accountid-005',
            createdAt: new Date('2024-02-07T15:38:41.466Z'),
            companyId: 'companyid-005',
            scans: 1,
            createdBy: 'preview-005',
            domain: 's.ypb.io',
            name: 'Preview 5',
            id: 'id-005',
            config: {
              redirectTo: 'connectqr.ai'
            },
            updatedAt: new Date('2024-02-07T15:38:41.466Z')
          },
          scans: 1
        }
      ]
    }
  },
  scansOverTime: {
    scans: [
      {
        date: '2024-02-01',
        count: 0
      },
      {
        date: '2024-02-02',
        count: 20
      },
      {
        date: '2024-02-03',
        count: 8
      },
      {
        date: '2024-02-04',
        count: 0
      },
      {
        date: '2024-02-05',
        count: 8
      },
      {
        date: '2024-02-06',
        count: 0
      },
      {
        date: '2024-02-07',
        count: 2
      },
      {
        date: '2024-02-08',
        count: 6
      },
      {
        date: '2024-02-09',
        count: 77
      },
      {
        date: '2024-02-10',
        count: 0
      }
    ]
  },
  topDay: [
    [
      'Day',
      'Scans',
      {
        role: 'style'
      },
      {
        role: 'annotation'
      }
    ],
    ['Sun', 150, '#dcdedf', 150],
    ['Mon', 200, '#dcdedf', 200],
    ['Tue', 359, '#dcdedf', 359],
    ['Wed', 172, '#dcdedf', 172],
    ['Thu', 314, '#dcdedf', 314],
    ['Fri', 140, '#dcdedf', 140],
    ['Mon', 380, '#040933', 380]
  ],
  topDayVAxisMax: 380,
  topTime: [
    [
      'Time',
      'Scans',
      {
        role: 'style'
      },
      {
        role: 'annotation'
      }
    ],
    ['4AM', 190, '#040933', 190],
    ['6AM', 158, '#dcdedf', 158],
    ['7AM', 157, '#dcdedf', 157],
    ['10AM', 111, '#dcdedf', 111],
    ['9AM', 104, '#dcdedf', 104]
  ],
  topTimeHAxisMax: 190,
  deviceBrowser: {
    total: 0,
    data: [
      {
        name: 'Chrome',
        count: 590
      },
      {
        name: 'Mobile Safari',
        count: 456
      },
      {
        name: 'Edge',
        count: 141
      },
      {
        name: 'Safari',
        count: 62
      }
    ]
  },
  deviceOs: {
    total: 0,
    data: [
      {
        name: 'Mac OS',
        count: 541
      },
      {
        name: 'iOS',
        count: 511
      },
      {
        name: 'Android',
        count: 177
      },
      {
        name: 'Windows',
        count: 20
      }
    ]
  },
  referrerDomain: {
    total: 0,
    data: [
      {
        name: 'Direct',
        count: 541
      },
      {
        name: 'Google',
        count: 511
      },
      {
        name: 'LinkedIn',
        count: 177
      },
      {
        name: 'Twitter',
        count: 20
      },
      {
        name: 'Facebook',
        count: 11
      },
      {
        name: 'Other',
        count: 4
      }
    ]
  }
};

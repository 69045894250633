import {
  ICompany,
  ICompanyDetails,
  ICreateCompany,
  ISearchableCompany,
  IUpdateCompany
} from '@/modules/company/company.interface';
import ClientInterface from '@/services/api/common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class Company {
  private readonly client: ClientInterface;

  private readonly resource: string = 'company';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedCompanies(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<ISearchableCompany>> {
    builder.setResource(`v1/${this.resource}`);
    return this.client.getPaginated<ISearchableCompany>(builder);
  }

  getCompanyById(id: string): Promise<ResourceResponse<ICompany | null>> {
    return this.client.getById(`v1/${this.resource}`, id);
  }

  getCompanyDetails(): Promise<ICompanyDetails> {
    return this.client.get(`${this.resource}/my-company`);
  }

  createCompany(body: ICreateCompany): Promise<ResourceResponse<ICompany>> {
    return this.client.create<ICreateCompany, ICompany>(`v1/${this.resource}`, body);
  }

  updateCompany(id: string, body: IUpdateCompany): Promise<ResourceResponse<ICompany>> {
    return this.client.patch(`v1/${this.resource}`, body, id);
  }

  deleteCompanyById(id: string): Promise<void> {
    return this.client.delete(`v1/${this.resource}`, id);
  }
}

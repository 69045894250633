import { MutationTree } from 'vuex';
import { ICampaign, ISearchableCampaign } from '@/modules/campaign/campaign.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import { ICampaignState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ICampaignState> = {
  UPDATE_PAGINATED_CAMPAIGN(
    state: ICampaignState,
    paginated_campaign: IPaginatedData<ISearchableCampaign>
  ) {
    state.paginated_campaign = paginated_campaign;
  },
  SELECTED_CAMPAIGN(state: ICampaignState, selected_campaign: ICampaign) {
    state.selected_campaign = selected_campaign;
  },
  UPDATE_SELECTED_CAMPAIGN(state: ICampaignState, update_selected_campaign: ICampaign) {
    state.update_selected_campaign = update_selected_campaign;
  }
};

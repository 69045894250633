// eslint-disable-next-line import/prefer-default-export
export enum EAiQrStatus {
  QUEUED = 'queued',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export enum EAiQrResolution {
  FULL_HD = 'full_hd',
  ULTRA_HD = 'ultra_hd'
}

import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { INotification } from '@/modules/notification/notification.interface';
import { INotificationState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<INotificationState, IRootState> = {
  total_notifications(state: INotificationState): number {
    const totalNotifications: number = state.all_notifications?.length;
    return totalNotifications;
  },
  total_unread_notifications(state: INotificationState): number {
    const totalUnreadNotifications: number = state.all_notifications?.filter(
      (item: INotification): boolean => !item.isRead
    )?.length;
    return totalUnreadNotifications;
  }
};

/* eslint-disable import/prefer-default-export */
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
// eslint-disable-next-line import/order
import { ActionTree } from 'vuex';
import { ReportState } from './types';

const ApiFactory = new Api();
const builder = new GeohashScansBuilder();

export const actions: ActionTree<ReportState, IRootState> = {
  GET_PAGINATED_REPORT_LIST({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      ApiFactory.getReport()
        .getPaginatedReports(builder)
        .then((response) => {
          commit('UPDATE_PAGINATED_REPORT_LIST', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_REPORT_SCAN_LOCATIONS_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.start);
      builder.setToDate(payload.end);
      builder.setBoundingBox(payload.boundingBox);

      ApiFactory.getReport()
        .getReportScanLocations(payload.id, builder)
        .then((response) => {
          commit('UPDATE_REPORT_SCAN_LOCATIONS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_REPORT_SCAN_RECORDS_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.start);
      builder.setToDate(payload.end);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);

      ApiFactory.getReport()
        .getPaginatedReportScans(payload.id, builder)
        .then((response) => {
          commit('UPDATE_REPORT_SCAN_RECORDS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};

/* eslint-disable import/prefer-default-export */
import { IAllAiQrResponse, ICreateAiQr } from '@/modules/aiqr/aiqr.interface';
import { ITopScannedLinkObject } from '@/modules/analytics/analytics/analytics.interface';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import { TQrType } from '@/modules/qr-generator/link-config.interface';
import {
  IAnalyticsPayload,
  ICreateLinkConnectQrApi,
  ICreateLinkStaticQrApi,
  ILinkConnectQr,
  ILinkConnectQrAllowance,
  ILinkConnectQrStats,
  ILinkStaticQr,
  ILinkTagResults,
  ISearchableLink,
  IUpdateLinkConnectQrApi,
  IUpdateLinkName,
  IUpdateLinkStaticQrApi
} from '@/modules/qr-generator/link.interface';
import { TConnectQrAction } from '@/modules/qr-generator/link.type';
import {
  ILinkScanMetricsAnalytics,
  ILinkScanPopularAnalytics,
  ILinkScanPopularTermsData,
  ILinkScannedAtAnalytics
} from '@/modules/scan/scan.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { loadingStatus } from '@/views/private/qr-generator/data/ai-qr';
import { allAnalticsType } from '@/views/private/qr-generator/data/analytics';
import {
  IAiQr,
  ILinkScanOverTimeCount,
  IScanLocationCount,
  ITopScannedLinkResponse,
  ITopScannedShortLinkResponse
} from '@connect-saas/types';
import { ActionContext, ActionTree } from 'vuex';
import { ILinkState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

export const actions: ActionTree<ILinkState, IRootState> = {
  GET_PAGINATED_LINKS(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableLink>>> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          let operator = EFilterOperators.IN;
          if (element.selectedFilter === 'configType') {
            operator = EFilterOperators.EQ;
          }
          builder.addFilter(element.selectedFilter, operator, element.value);
        });
      }
      ApiFactory.getLink()
        .getAllPaginatedLinks(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableLink>>) => {
          commit('UPDATE_PAGINATED_LINKS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONNECT_QR_BY_ID(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    id: string
  ): Promise<ResourceResponse<ILinkConnectQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .getLinkById(id)
        .then((response: ResourceResponse<ILinkConnectQr>) => {
          commit('SELECTED_CONNECT_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_CONNECT_QR(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: ICreateLinkConnectQrApi
  ): Promise<ResourceResponse<ILinkConnectQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .createLink(payload)
        .then((response: ResourceResponse<ILinkConnectQr>) => {
          commit('UPDATE_SELECTED_CONNECT_QR', response.resource);
          commit('UPDATE_NEWLY_CREATED_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_CONNECT_QR(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; body: IUpdateLinkConnectQrApi }
  ): Promise<ResourceResponse<ILinkConnectQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .updateLink(payload.id, payload.body)
        .then((response: ResourceResponse<ILinkConnectQr>) => {
          commit('UPDATE_SELECTED_CONNECT_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_STATIC_QR_BY_ID(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    id: string
  ): Promise<ResourceResponse<ILinkStaticQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('static-qr')
        .getLinkById(id)
        .then((response: ResourceResponse<ILinkStaticQr>) => {
          commit('SELECTED_STATIC_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_STATIC_QR(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: ICreateLinkStaticQrApi
  ): Promise<ResourceResponse<ILinkStaticQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('static-qr')
        .createLink(payload)
        .then((response: ResourceResponse<ILinkStaticQr>) => {
          commit('UPDATE_SELECTED_STATIC_QR', response.resource);
          commit('UPDATE_NEWLY_CREATED_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_STATIC_QR(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; body: IUpdateLinkStaticQrApi }
  ): Promise<ResourceResponse<ILinkStaticQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('static-qr')
        .updateLink(payload.id, payload.body)
        .then((response: ResourceResponse<ILinkStaticQr>) => {
          commit('UPDATE_SELECTED_STATIC_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_QR_NAME(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; body: IUpdateLinkName }
  ): Promise<ResourceResponse<ILinkConnectQr | ILinkStaticQr>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink(payload.body.type)
        .updateLink(payload.id, { name: payload.body.name })
        .then((response: ResourceResponse<ILinkConnectQr | ILinkStaticQr>) => {
          commit('UPDATE_SELECTED_QR_NAME', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_QR_BY_ID(
    { dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; qrType: TQrType }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink(payload.qrType)
        .deleteLinkById(payload.id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SCANNED_AT(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<ILinkScannedAtAnalytics> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getScannedAt(payload)
        .then((response: ILinkScannedAtAnalytics) => {
          commit('UPDATE_SCANNED_AT', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SCANNED_AT_BY_METRICS(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<ILinkScanMetricsAnalytics> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getScannedAtByMetrics(payload)
        .then((response: ILinkScanMetricsAnalytics) => {
          commit('UPDATE_SCANNED_AT_BY_METRICS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SCANNED_AT_BY_LOCATION(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<IScanLocationCount[]> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getScannedAtByLocation(payload)
        .then((response: IScanLocationCount[]) => {
          commit('UPDATE_SCANNED_AT_BY_LOCATION', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SCAN_TIME_SERIES(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<ILinkScanOverTimeCount> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getScanTimeSeries(payload)
        .then((response: ILinkScanOverTimeCount) => {
          commit('UPDATE_SCAN_TIME_SERIES', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_POPULAR_SCANS_BY_DIMENSIONS(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<ILinkScanPopularAnalytics> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getPopularScansByDimensions(payload)
        .then((response: ILinkScanPopularAnalytics) => {
          commit('UPDATE_POPULAR_SCANS_BY_DIMENSIONS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SCANNED_AT_BY_LOCATION_COUNTRIES(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<ILinkScanPopularTermsData> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getScannedAtByLocationCountries(payload)
        .then((response: ILinkScanPopularTermsData) => {
          commit('UPDATE_SCANNED_AT_BY_LOCATION_COUNTRIES', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SCAN_TOP_LINKS_SETTING(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<ITopScannedLinkObject> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let type = [...allAnalticsType.value];
      if (payload.type) {
        type = [payload.type];
      }
      const response = {};
      await Promise.all(
        type.map(async (item) => {
          response[item] = await dispatch('GET_SCAN_TOP_LINKS', { ...payload, type: item });
        })
      );
      await commit('UPDATE_SCAN_TOP_LINKS', response);
      resolve(response);
    });
  },
  GET_SCAN_TOP_LINKS(
    { dispatch }: ActionContext<ILinkState, IRootState>,
    payload: IAnalyticsPayload
  ): Promise<(ITopScannedLinkResponse | ITopScannedShortLinkResponse)[]> {
    return new Promise((resolve, reject) => {
      const typeList = {
        connect_qr: 'getScanTopLinks',
        short_url: 'getScanTopShortLinks'
      };
      ApiFactory.getLink()
        [typeList[payload.type]](payload)
        .then((response: { data: (ITopScannedLinkResponse | ITopScannedShortLinkResponse)[] }) => {
          resolve(response.data);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONNECT_QR_STATS({
    dispatch,
    commit
  }: ActionContext<ILinkState, IRootState>): Promise<ILinkConnectQrStats> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getConnectQrStats()
        .then((response: ILinkConnectQrStats) => {
          commit('UPDATE_CONNECT_QR_STATS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CONNECT_QR_ALLOWANCE({
    dispatch,
    commit
  }: ActionContext<ILinkState, IRootState>): Promise<ILinkConnectQrAllowance> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getConnectQrAllowance()
        .then((response: ILinkConnectQrAllowance) => {
          commit('UPDATE_CONNECT_QR_ALLOWANCE', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_LINK_TAGS({
    dispatch,
    commit
  }: ActionContext<ILinkState, IRootState>): Promise<ILinkTagResults> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink()
        .getLinkTags()
        .then((response: ILinkTagResults) => {
          commit('UPDATE_TAGS', response?.data);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  PAUSE_ACTIVATE_CONNECT_QR(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; action: TConnectQrAction }
  ): Promise<ResourceResponse<void>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .pause(payload)
        .then((response: ResourceResponse<void>) => {
          commit('UPDATE_SELECTED_CONNECT_QR', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GENERATE_AI_QR(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: ICreateAiQr
  ): Promise<IAiQr> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .generateAiQr(payload)
        .then((response: ResourceResponse<IAiQr>) => {
          commit('UPDATE_SELECTED_AI_QR', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_AI_QR(
    { dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { linkId: string; id: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .deleteAiQr(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_ALL_AI_QR(
    { dispatch, commit }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; isInterval?: boolean } = { id: undefined, isInterval: false }
  ): Promise<IAiQr[]> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink('connect-qr')
        .getAllAiQr(payload.id)
        .then((response: IAllAiQrResponse) => {
          commit('UPDATE_ALL_AI_QR', response?.data);
          if (payload.isInterval && response?.data?.length) {
            const latestQr: IAiQr = response.data[response.data.length - 1];
            if (!loadingStatus.includes(latestQr?.status)) {
              commit('UPDATE_SELECTED_AI_QR', latestQr);
            }
          }
          resolve(response?.data);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DUPLICATE_QR(
    { dispatch }: ActionContext<ILinkState, IRootState>,
    payload: { id: string; qrType: TQrType }
  ): Promise<ILinkStaticQr | ILinkConnectQr> {
    return new Promise((resolve, reject) => {
      ApiFactory.getLink(payload.qrType)
        .duplicate(payload.id)
        .then((response: ResourceResponse<ILinkStaticQr | ILinkConnectQr>) => {
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

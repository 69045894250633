import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IChangeProfilePasswordParams } from '@/modules/profile/profile.interface';
import {
  IChangeUserEmail,
  IUpdateUserProfileParams,
  IUserProfile,
  IUserRules
} from '@/modules/user/user.interface';
// eslint-disable-next-line import/no-cycle
import { IImageUpload, IImageUploadRequest } from '@/modules/image/image.interface';
import Api from '@/services/api/ApiFactory';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IProfileState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IProfileState, IRootState> = {
  GET_PROFILE({ commit, dispatch }: ActionContext<IProfileState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .getProfile()
        .then((response: IUserProfile | null) => {
          commit('SELECTED_PROFILE', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_PROFILE(
    { commit, dispatch }: ActionContext<IProfileState, IRootState>,
    payload: IUpdateUserProfileParams
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .updateProfile(payload)
        .then((response: ResourceResponse<IUserProfile>) => {
          commit('UPDATE_SELECTED_PROFILE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CHANGE_PASSWORD(
    { commit, dispatch }: ActionContext<IProfileState, IRootState>,
    payload: IChangeProfilePasswordParams
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .changePassword(payload)
        .then((response: ResourceResponse<IUserProfile>) => {
          commit('UPDATE_SELECTED_PROFILE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPLOAD_USER_PROFILE_IMAGE(
    { commit, dispatch }: ActionContext<IProfileState, IRootState>,
    payload: IImageUploadRequest
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .uploadUserProfileImage(payload)
        .then((response: ResourceResponse<IImageUpload>) => {
          commit('UPDATE_SELECTED_PROFILE_IMAGE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CHANGE_PROFILE_EMAIL(
    { commit, dispatch }: ActionContext<IProfileState, IRootState>,
    payload: IChangeUserEmail
  ): Promise<ResourceResponse<IUserProfile>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .changeProfileEmail(payload)
        .then((response: ResourceResponse<IUserProfile>) => {
          commit('UPDATE_SELECTED_PROFILE_IMAGE', response.resource);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  VERIFY_CHANGE_EMAIL(
    { dispatch }: ActionContext<IProfileState, IRootState>,
    payload: string
  ): Promise<ResourceResponse<void>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .verifyChangeEmail(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_CASL_RULES({
    commit,
    dispatch
  }: ActionContext<IProfileState, IRootState>): Promise<IUserRules> {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .getCaslRules()
        .then((response: IUserRules) => {
          commit('SELECTED_CASL_RULES', response.rules);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  SWITCH_COMPANY(
    { commit, dispatch }: ActionContext<IProfileState, IRootState>,
    payload: string
  ): Promise<ResourceResponse<IUserRules>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getProfile()
        .switchCompany(payload)
        .then((response: ResourceResponse<IUserRules>) => {
          commit('authentication/UPDATE_DEFAULT_COMPANY_ID', response.resource.companyId, {
            root: true
          });
          commit('SELECTED_CASL_RULES', response.resource.rules);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

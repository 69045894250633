/* eslint-disable import/prefer-default-export */
import { DashboardState } from '@/store/modules/dashboard/types';
// eslint-disable-next-line import/order
import { MutationTree } from 'vuex';

export const mutations: MutationTree<DashboardState> = {
  UPDATE_TIME_SERIES_DATA(state, time_series_data) {
    state.time_series_data = time_series_data;
  },
  UPDATE_STATISTICS_DATA(state, statistics_data) {
    state.statistics_data = statistics_data;
  },
  UPDATE_MOST_SCANNED_PRODUCTS_DATA(state, most_scanned_products_data) {
    state.most_scanned_products_data = most_scanned_products_data;
  },
  UPDATE_GEOGRAPHICS_DATA(state, geographics_data) {
    state.geographics_data = geographics_data;
  },
  UPDATE_DEMOGRAPHICS_DATA(state, demographics_data) {
    state.demographics_data = demographics_data;
  },
  UPDATE_MOST_ENGAGED_PRODUCTS_DATA(state, most_engaged_products_data) {
    state.most_engaged_products_data = most_engaged_products_data;
  },
  UPDATE_MOST_ENGAGED_CAMPAIGNS_DATA(state, most_engaged_campaigns_data) {
    state.most_engaged_campaigns_data = most_engaged_campaigns_data;
  }
};

import { IUserProfile } from '@/modules/user/user.interface';
import Client from './common/Client';
import ResourceResponse from './common/ResourceResponse';
import { IAuthedUser, ISignUpApi, ISignUpSsoApi } from '@connect-saas/types';

export default class Signup {
  private readonly client: Client;

  private readonly resource = 'signup';

  constructor(client: Client) {
    this.client = client;
  }

  userSignUp(payload: ISignUpApi): Promise<ResourceResponse<IUserProfile>> {
    return this.client.post(`${this.resource}`, payload);
  }

  ssoSignup(payload: ISignUpSsoApi): Promise<ResourceResponse<IAuthedUser>> {
    return this.client.post(`${this.resource}/sso`, payload);
  }

  canvaSignup(payload: ISignUpApi): Promise<ResourceResponse<IUserProfile>> {
    return this.client.post(`${this.resource}/canva`, payload);
  }
}

import { TBoundingBox, TSearchAfter } from '@/modules/common/service.type';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';

export default class GeohashScansBuilder extends PaginationBuilder {
  private precision: number;

  private boundingBox: TBoundingBox;

  private searchAfter: TSearchAfter;

  public getPrecision(): number {
    return this.precision;
  }

  public setPrecision(precision: number): GeohashScansBuilder {
    this.precision = precision;
    return this;
  }

  public getBoundingBox(): TBoundingBox {
    return this.boundingBox;
  }

  public setBoundingBox(boundingBox: TBoundingBox): GeohashScansBuilder {
    this.boundingBox = boundingBox;
    return this;
  }

  public getSearchAfter(): TSearchAfter {
    return this.searchAfter;
  }

  public setSearchAfter(searchAfter: TSearchAfter): GeohashScansBuilder {
    this.searchAfter = searchAfter;
    return this;
  }
}

import { createGlobalState, useStorage } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useJwt } from '@vueuse/integrations/useJwt';

const cookies = useCookies(['locale']);
let useDecodedJwt = null;
export const decodedJwt = () => {
  useDecodedJwt = null;
  const encodedJwt = cookies.get('atp');
  if (encodedJwt) {
    const { header } = useJwt(encodedJwt);
    useDecodedJwt = {
      accountId: (header.value as any)?.accountId,
      companies: (header.value as any)?.companies,
      defaultCompanyId: (header.value as any)?.defaultCompanyId,
      email: (header.value as any)?.email,
      name: (header.value as any)?.name,
      roles: (header.value as any)?.roles,
      rules: (header.value as any)?.rules,
      timezone: (header.value as any)?.timezone,
      iat: new Date((header.value as any)?.iat),
      requiresEmailVerification: (header.value as any)?.requiresEmailVerification
    };
  }
  return useDecodedJwt;
};

export const useRemoveCookie = () => {
  cookies.remove('atp', { path: '/' });
};

export const useDefaultCompanyId = createGlobalState(() =>
  useStorage('defaultCompanyId', useDecodedJwt?.defaultCompanyId ?? null)
);

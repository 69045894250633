import { EGranularity } from '@/modules/common/common.enum';
import ClientInterface from '../common/ClientInterface';

export default class AnalyticsBuilder {
  private resource: string;

  private client: ClientInterface;

  constructor(resource: string, client: ClientInterface) {
    this.resource = resource;
    this.client = client;
  }

  setFrom(from: string): AnalyticsBuilder {
    this.resource += `?from=${from}`;
    return this;
  }

  setTo(to: string): AnalyticsBuilder {
    this.resource += `&to=${to}`;
    return this;
  }

  setMetrics(metrics: string): AnalyticsBuilder {
    this.resource += `&metrics=${metrics}`;
    return this;
  }

  setFilter(filterKey: string, filterValue: string): AnalyticsBuilder {
    this.resource += `&filter.${filterKey}=${filterValue}`;
    return this;
  }

  setBoundingBox(boundingBox: string): AnalyticsBuilder {
    this.resource += `&boundingBox=${boundingBox}`;
    return this;
  }

  setPrecision(precision: number): AnalyticsBuilder {
    this.resource += `&precision=${precision}`;
    return this;
  }

  setGranularity(granularity: EGranularity): AnalyticsBuilder {
    this.resource += `&granularity=${granularity}`;
    return this;
  }

  setDimensions(dimensions: string): AnalyticsBuilder {
    this.resource += `&dimensions=${dimensions}`;
    return this;
  }

  setTop(top: number): AnalyticsBuilder {
    this.resource += `&top=${top}`;
    return this;
  }

  async get<T>(): Promise<T> {
    const response = await this.client.get<T>(this.resource);
    return response;
  }
}

import { IProductList } from '@/modules/product/product.interface';
import ClientInterface from '@/services/api/common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';

export default class EngagedProduct {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/engagement/product';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedEngagedProducts(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IProductList>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IProductList>(builder);
  }
}

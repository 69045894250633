import {
  ICampaign,
  IProductCampaignStats,
  ITechScanStats,
  ITechScanTimeline
} from '@/modules/analytics/analytics/analytics.interface';
import { EGranularity } from '@/modules/common/common.enum';
import { TPayload } from '@/modules/common/common.type';
import { IScansList } from '@/modules/common/location.interface';
import { EAnalyticsType } from '@/modules/common/service.enum';
import { ITech } from '@/modules/common/service.interface';
import { IProduct } from '@/modules/product/product.interface';
import { IPaginatedScans } from '@/modules/scan/scan.interface';
import ClientInterface from '@/services/api/common/ClientInterface';
import { IScanRecord } from '@connect-saas/types';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class Analytics {
  private readonly client: ClientInterface;

  private readonly resource: EAnalyticsType;

  constructor(client: ClientInterface, resource: EAnalyticsType) {
    this.client = client;
    this.resource = resource;
  }

  getTechById(id: string | number): Promise<ResourceResponse<ITech>> {
    return this.client.getById(this.resource, id);
  }

  getProductById(id: string | number): Promise<ResourceResponse<IProduct>> {
    return this.client.getById(this.resource, id);
  }

  getCampaignById(id: string): Promise<ResourceResponse<ICampaign>> {
    return this.client.getById(`v2/${this.resource}`, id);
  }

  getAnalyticsScanStats(
    payload: TPayload<EGranularity>,
    version?: string
  ): Promise<ResourceResponse<ITechScanStats>> {
    let path = `${this.resource}/${payload.id}/scan-stats?from=${payload.from}&to=${payload.to}`;
    if (version) {
      path = `${version}/${this.resource}/${payload.id}/scan-stats?from=${payload.from}&to=${payload.to}`;
    }
    return this.client.get(path);
  }

  getAnalyticsScanLocations(
    id: string | number,
    builder: GeohashScansBuilder = new GeohashScansBuilder(),
    version?: string
  ): Promise<GeohashScansResponse<IScansList>> {
    let resource = `${this.resource}/${id}/scan-locations`;
    if (version) {
      resource = `${version}/${this.resource}/${id}/scan-locations`;
    }
    builder.setResource(resource);
    return this.client.getScansByGeohash<IScansList>(builder);
  }

  getAnalyticsScansTimeline(
    payload: TPayload<EGranularity>
  ): Promise<ResourceResponse<ITechScanTimeline[]>> {
    return this.client.get(
      `v2/${this.resource}/${payload.id}/scan-time-series?from=${payload.from}&to=${payload.to}&granularity=${payload.granularity}&scanType=campaign`
    );
  }

  getAnalyticsScans(
    id: string | number,
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IScanRecord>> {
    builder.setResource(`${this.resource}/${id}/scans`);
    return this.client.getPaginated<IScanRecord>(builder);
  }

  getAnalyticsCampaignStats(
    payload: TPayload<EGranularity>
  ): Promise<ResourceResponse<IProductCampaignStats[]>> {
    return this.client.get(
      `${this.resource}/${payload.id}/campaign-stats?from=${payload.from}&to=${payload.to}`
    );
  }

  getAnalyticsScansByIdAndScanType(
    id: string | number,
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedScans>> {
    builder.setResource(`v2/${this.resource}/${id}/scans`);
    return this.client.getPaginated<IPaginatedScans>(builder);
  }
}

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { AudienceState } from './types';

const state: AudienceState = {
  paginated_audience_list: {
    resources: [],
    meta: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 6,
      totalPages: 1
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const audience: Module<AudienceState, IRootState> = {
  state,
  getters,
  mutations,
  actions
};

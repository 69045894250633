// eslint-disable-next-line import/order
import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { ReportState } from './types';

const state: ReportState = {
  paginated_report_list: {
    resources: [],
    meta: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 6,
      totalPages: 1
    }
  },
  report_scan_locations: {
    resources: [
      {
        location: {
          lat: 0,
          lng: 0
        },
        count: 0
      }
    ]
  },
  report_scan_records: {
    resources: null,
    meta: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 6,
      totalPages: 1
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const report: Module<ReportState, IRootState> = {
  state,
  getters,
  mutations,
  actions
};

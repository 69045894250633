import { EDateHistogramGranularity } from '@/modules/analytics-report/analytics-report.enum';
import {
  IAnalyticsReport,
  IAnalyticsReportFilterLookups,
  IAnalyticsScanStats,
  IAnalyticsScanTimeSeries,
  ICreateAnalyticsReport,
  ISearchableAnalyticsReport,
  IUpdateAnalyticsReport
} from '@/modules/analytics-report/analytics-report.interface';
import { EGranularity } from '@/modules/common/common.enum';
import { TPayload } from '@/modules/common/common.type';
import { IScansList } from '@/modules/common/location.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import { EScanType } from '@/modules/scan/scan.enum';
import { IPaginatedScans } from '@/modules/scan/scan.interface';
import ClientInterface from './common/ClientInterface';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class AnalyticsReport {
  private readonly client: ClientInterface;

  private readonly resource: string = 'analytics/report';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAnalyticsReports(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableAnalyticsReport>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchableAnalyticsReport>>(builder);
  }

  getAnalyticsReportById(id: number | string): Promise<ResourceResponse<IAnalyticsReport>> {
    return this.client.getById(this.resource, id);
  }

  deleteAnalyticsReportById(id: number | string): Promise<void> {
    return this.client.delete(this.resource, id);
  }

  getAnalyticsReportExportById(id: number | string): Promise<ResourceResponse<IAnalyticsReport>> {
    return this.client.getById(`${this.resource}/${id}/exports`, id);
  }

  getAnalyticsReportScansComponentById(
    id: string | number,
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedScans>> {
    builder.setResource(`${this.resource}/${id}/scans`);
    builder.setScanType(EScanType.CAMPAIGN);
    return this.client.getPaginated<IPaginatedScans>(builder);
  }

  getAnalyticsReportScanLocationsById(
    id: string | number,
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScansList>> {
    builder.setResource(`${this.resource}/${id}/scan-locations`);
    builder.setScanType(EScanType.CAMPAIGN);
    return this.client.getScansByGeohash<IScansList>(builder);
  }

  createAnalyticsReportById(
    body: ICreateAnalyticsReport
  ): Promise<ResourceResponse<IAnalyticsReport>> {
    return this.client.create<ICreateAnalyticsReport, IAnalyticsReport>(this.resource, body);
  }

  updateAnalyticsReportById(
    id: string | number,
    body: IUpdateAnalyticsReport
  ): Promise<ResourceResponse<IAnalyticsReport>> {
    return this.client.update<IUpdateAnalyticsReport, IAnalyticsReport>(this.resource, id, body);
  }

  getAnalyticsReportScanTimeSeries(
    payload: TPayload<EDateHistogramGranularity>
  ): Promise<ResourceResponse<IAnalyticsScanTimeSeries>> {
    const scanType = payload.scanType ? `&scanType=${payload.scanType}` : '';
    return this.client.get(
      `${this.resource}/${payload.id}/scan-time-series?from=${payload.from}&to=${payload.to}&granularity=${payload.granularity}${scanType}`
    );
  }

  getAnalyticsReportScanStats(
    payload: TPayload<EGranularity>
  ): Promise<ResourceResponse<IAnalyticsScanStats>> {
    const scanType = payload.scanType ? `&scanType=${payload.scanType}` : '';
    return this.client.get(
      `${this.resource}/${payload.id}/scan-stat-counts?from=${payload.from}&to=${payload.to}${scanType}`
    );
  }

  getAnalyticsReportFilterLookups(): Promise<ResourceResponse<IAnalyticsReportFilterLookups>> {
    return this.client.get(`${this.resource}/filter-lookups`);
  }
}

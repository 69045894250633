import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
// eslint-disable-next-line import/order
import { ActionTree } from 'vuex';
import { AnalyticsState } from './types';

const ApiFactory = new Api();
const builder = new GeohashScansBuilder();
const paginationBuilder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<AnalyticsState, IRootState> = {
  GET_TECH_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getTechById(payload.id)
        .then((response) => {
          commit('SELECTED_TECH', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_PRODUCT_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getProductById(payload.id)
        .then((response) => {
          commit('SELECTED_TECH', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_CAMPAIGN_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getCampaignById(payload.id)
        .then((response) => {
          commit('SELECTED_CAMPAIGN', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_STATS({ commit, dispatch }, payload) {
    let version;
    if (payload.analyticsType === 'campaign') {
      version = 'v2';
    }
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getAnalyticsScanStats(payload, version)
        .then((response) => {
          commit('UPDATE_ANALYTICS_SCAN_STATS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_LOCATIONS({ commit, dispatch }, payload) {
    let version;
    if (payload.analyticsType === 'campaign') {
      version = 'v2';
    }
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setBoundingBox(payload.boundingBox);
      ApiFactory.getAnalytics(payload.resource)
        .getAnalyticsScanLocations(payload.id, builder, version)
        .then((response) => {
          commit('UPDATE_ANALYTICS_SCAN_LOCATIONS', response.resources);
          resolve(response.resources);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCANS_TIMELINE({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getAnalyticsScansTimeline(payload)
        .then((response) => {
          commit('UPDATE_ANALYTICS_SCANS_TIMELINE', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCANS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      paginationBuilder.setFromDate(payload.from);
      paginationBuilder.setToDate(payload.to);
      paginationBuilder.setPage(payload.page);
      paginationBuilder.setPageSize(payload.itemsPerPage);
      paginationBuilder.setScanType(payload.scanType);
      ApiFactory.getAnalytics(payload.resource)
        .getAnalyticsScansByIdAndScanType(payload.id, paginationBuilder)
        .then((response) => {
          commit('UPDATE_ANALYTICS_SCANS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_CAMPAIGN_STATS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalytics(payload.resource)
        .getAnalyticsCampaignStats(payload)
        .then((response) => {
          commit('UPDATE_ANALYTICS_CAMPAIGN_STATS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

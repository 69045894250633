import { IDemographics } from '@/modules/dashboard/dashboard.interface';
import ClientInterface from '@/services/api/common/ClientInterface';

export default class Demography {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/demographics';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getGenderAndAgeRange(): Promise<IDemographics> {
    return this.client.get<IDemographics>(this.resource);
  }
}

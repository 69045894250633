import { TPaginatedLinks, TPaginatedMeta } from '@/modules/common/service.type';

export default class PaginatedResponse<T> {
  public readonly status: number;

  public readonly resources: T[];

  public readonly meta: TPaginatedMeta;

  public readonly links: TPaginatedLinks;

  constructor(status: number, resources: T[], meta: TPaginatedMeta, links: TPaginatedLinks) {
    this.status = status;
    this.resources = resources;
    this.meta = meta;
    this.links = links;
  }
}

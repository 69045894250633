export enum EAccountTiers {
  ENTERPRISE = 'enterprise',
  FREE = 'free',
  LITE = 'lite',
  PLUS = 'plus',
  PRO = 'pro',
}

export enum EAccountStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DELETED = 'deleted',
}

export enum EAccountPaymentMethodStatus {
  NONE = 'none',
  VALID = 'valid',
  EXPIRED = 'expired',
}

import { MutationTree } from 'vuex';
import {
  IMetadataField,
  ISearchableMetadataField
} from '@/modules/metadata-admin/metadata.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import { IMetadataState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IMetadataState> = {
  SELECTED_META_DATA(state: IMetadataState, selected_metadata: IMetadataField) {
    state.selected_metadata = selected_metadata;
  },
  UPDATE_SELECTED_META_DATA(state: IMetadataState, update_selected_metadata: IMetadataField) {
    state.update_selected_metadata = update_selected_metadata;
  },
  UPDATE_PAGINATED_META_DATA(
    state: IMetadataState,
    paginated_metadata: IPaginatedData<ISearchableMetadataField>
  ) {
    state.paginated_metadata = paginated_metadata;
  }
};

import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { ILinkFormSubmissionCount } from '@/modules/form/form.interface';
import { IFormState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<IFormState, IRootState> = {
  submission_links_list(state: IFormState): ILinkFormSubmissionCount[] {
    return state.selected_form_submission_links?.links || [];
  }
};

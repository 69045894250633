import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { ICompanyState } from './types';

const state: ICompanyState = {
  paginated_companies: null,
  selected_company: null,
  updated_selected_company: null,
  created_company: null
};

// eslint-disable-next-line import/prefer-default-export
export const company: Module<ICompanyState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

import { TSearchBy } from '@/modules/common/service.type';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';

export default (builder: PaginationBuilder): TSearchBy => {
  const searchBy = [];
  builder.getSearchBy().forEach((builderSearchBy) => {
    searchBy.push(builderSearchBy.value);
  });
  return searchBy;
};

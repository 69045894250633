import {
  ICreateProductApi,
  IProduct,
  IUpdateProductApi
} from '@/modules/product/product.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class ProductV2 {
  private readonly client: ClientInterface;

  private readonly resource: string = 'product';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedProducts(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IProduct[]>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IProduct[]>(builder);
  }

  getProductById(id: string): Promise<ResourceResponse<IProduct | null>> {
    return this.client.getById(`${this.resource}`, id);
  }

  createProduct(body: ICreateProductApi): Promise<ResourceResponse<IProduct>> {
    return this.client.create<ICreateProductApi, IProduct>(`${this.resource}`, body);
  }

  updateProduct(id: string, body: IUpdateProductApi): Promise<ResourceResponse<IProduct>> {
    return this.client.patch(`${this.resource}`, body, id);
  }

  deleteProductById(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }
}

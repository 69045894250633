import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import { ETechBatchType } from '@/modules/tech-batch/tech-batch.enum';
import {
  ICreateProtectCodePlusBatch,
  ICreateQrBatch,
  IResPresignedUrlZip,
  ISearchableProtectCodePlusTechBatch,
  ISearchableQrTechBatch,
  ITechBatchProtectCodePlus,
  ITechBatchQr,
  IUpdateProtectCodePlusBatch,
  TTechBatchAction
} from '@/modules/tech-batch/tech-batch.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { ITechBatchState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ITechBatchState, IRootState> = {
  GET_PAGINATED_TECH_BATCH(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTechBatch(ETechBatchType.QR)
        .getPaginatedTech<ISearchableQrTechBatch>(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableQrTechBatch>>) => {
          commit('UPDATE_PAGINATED_TECH_BATCH', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_TECH_BATCH_BY_ID(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    id: string
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.QR)
        .getTechById<ITechBatchQr>(id)
        .then((response: ResourceResponse<ITechBatchQr | null>) => {
          commit('SELECTED_TECH_BATCH', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_TECH_BATCH(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload: ICreateQrBatch
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.QR)
        .createTechBatch(payload)
        .then((response: ResourceResponse<ITechBatchQr>) => {
          const getTechBatchPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          commit('SELECTED_TECH_BATCH', response.resource);
          dispatch('GET_PAGINATED_TECH_BATCH', getTechBatchPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_TECH_BATCH(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload: { id: string; body: ICreateQrBatch }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.QR)
        .updateTechBatch(payload.id, payload.body)
        .then((response: ResourceResponse<ITechBatchQr>) => {
          const getTechBatchPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          commit('UPDATE_SELECTED_TECH_BATCH', response.resource);
          dispatch('GET_PAGINATED_TECH_BATCH', getTechBatchPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_TECH({ dispatch }: ActionContext<ITechBatchState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.QR)
        .deleteTechBatchById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },

  GET_QR_BATCH_DOWNLOAD_URL(
    { dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload: { id: string; type: ETechBatchType; action: TTechBatchAction }
  ): Promise<ResourceResponse<IResPresignedUrlZip>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(payload.type)
        .getQrBatchDownloadUrlByAction(payload.id, payload.action)
        .then((response: ResourceResponse<IResPresignedUrlZip>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_PROTECTCODE_PLUS_TECH_BATCH(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getTechBatch(ETechBatchType.PROTECTCODE_PLUS)
        .getPaginatedTech<ISearchableProtectCodePlusTechBatch>(builder)
        .then(
          (response: PaginatedResponse<IPaginatedData<ISearchableProtectCodePlusTechBatch>>) => {
            commit('UPDATE_PAGINATED_PROTECTCODE_PLUS_TECH_BATCH', response);
            resolve(response);
          }
        )
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PROTECTCODE_PLUS_BATCH_BY_ID(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.PROTECTCODE_PLUS)
        .getTechById<ITechBatchProtectCodePlus>(id)
        .then((response: ResourceResponse<ITechBatchProtectCodePlus | null>) => {
          commit('SELECTED_PROTECTCODE_PLUS_BATCH', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_PROTECTCODE_PLUS_BATCH(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload: ICreateProtectCodePlusBatch
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.PROTECTCODE_PLUS)
        .createProtectCodePlusBatch(payload)
        .then((response: ResourceResponse<ITechBatchProtectCodePlus>) => {
          const getProtectCodePlusBatchPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          commit('SELECTED_PROTECTCODE_PLUS_BATCH', response.resource);
          dispatch('GET_PAGINATED_PROTECTCODE_PLUS_TECH_BATCH', getProtectCodePlusBatchPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_TECH_BATCH_PROTECTCODE_PLUS(
    { commit, dispatch }: ActionContext<ITechBatchState, IRootState>,
    payload: { id: string; body: IUpdateProtectCodePlusBatch }
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.PROTECTCODE_PLUS)
        .updateTechBatchProtectCodePlus(payload.id, payload.body)
        .then((response: ResourceResponse<ITechBatchProtectCodePlus>) => {
          const getProtectCodePlusBatchPayload = {
            page: 1,
            itemsPerPage: 15,
            sort: { field: null, direction: null }
          };
          commit('SELECTED_PROTECTCODE_PLUS_BATCH', response.resource);
          dispatch('GET_PAGINATED_PROTECTCODE_PLUS_TECH_BATCH', getProtectCodePlusBatchPayload);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_SELECTED_PROTECTCODE_PLUS_BATCH(
    { dispatch }: ActionContext<ITechBatchState, IRootState>,
    id: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTechBatch(ETechBatchType.PROTECTCODE_PLUS)
        .deleteTechBatchById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

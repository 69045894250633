import ClientInterface from '@/services/api/common/ClientInterface';
import { TIntegrationType } from '@/views/private/integration/types/type';
import ResourceResponse from './common/ResourceResponse';

export default class Integration {
  private readonly client: ClientInterface;

  private readonly type: string;

  private readonly resource: string;

  constructor(client: ClientInterface, type: TIntegrationType) {
    this.client = client;
    let newType: string = type;
    if (type === 'meta_pixel') {
      newType = 'meta-pixel';
    }
    this.type = newType;
    this.resource = 'integration';
  }

  getAllIntegration<T>(): Promise<T> {
    return this.client.get(this.resource);
  }

  getIntegrationData<T>(): Promise<T> {
    return this.client.get(`${this.resource}/${this.type}`);
  }

  createIntegration<T, G>(body: T): Promise<ResourceResponse<G>> {
    return this.client.create<T, G>(`${this.resource}/${this.type}`, body);
  }

  deleteIntegration(): Promise<void> {
    return this.client.delete(`${this.resource}/${this.type}`);
  }
}

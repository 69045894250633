import {
  ICampaign,
  ICreateCampaignParams,
  ISearchableCampaign,
  IUpdateCampaignParams
} from '@/modules/campaign/campaign.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';

export default class Campaign {
  private readonly client: ClientInterface;

  private readonly resource: string = 'campaign';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getPaginatedCampaigns(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableCampaign>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchableCampaign>>(builder);
  }

  getCampaignById(id: string): Promise<ResourceResponse<ICampaign>> {
    return this.client.getById(this.resource, id);
  }

  createCampaignById(body: ICreateCampaignParams): Promise<ResourceResponse<ICampaign>> {
    return this.client.create<ICreateCampaignParams, ICampaign>(this.resource, body);
  }

  updateCampaign(id: string, body: IUpdateCampaignParams): Promise<ResourceResponse<ICampaign>> {
    return this.client.patch(this.resource, body, id);
  }

  deleteCampaignById(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }
}

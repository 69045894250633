import { ActionContext, ActionTree } from 'vuex';
import { IQrSearchFilter, IQrSearchResponse } from '@/modules/tech/tech.interface';
import Api from '@/services/api/ApiFactory';
import { IRootState } from '@/modules/common/common.interface';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IMetaDataFieldsResponse } from '@/modules/metadata/metadata.interface';
import { ITechState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<ITechState, IRootState> = {
  GET_TECH_META_DATA({
    dispatch,
    commit
  }: ActionContext<ITechState, IRootState>): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTech()
        .getTechMetaData()
        .then((response: ResourceResponse<IMetaDataFieldsResponse>) => {
          commit('UPDATE_TECH_META_DATA', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  SEARCH_QR_BY_FILTER(
    { commit, dispatch }: ActionContext<ITechState, IRootState>,
    payload: IQrSearchFilter
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      ApiFactory.getTech()
        .searchQrByProductOrBatch(payload)
        .then((response: ResourceResponse<IQrSearchResponse>) => {
          commit('UPDATE_SEARCHED_QR_CODES', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import {
  IForm,
  IFormSubmissionLinkResponse,
  ISearchableForm,
  ISearchableFormSubmission
} from '@/modules/form/form.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IFormState } from './types';
import FormSubmissionQueryBuilder from '@/services/api/common/FormSubmissionQueryBuilder';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IFormState, IRootState> = {
  GET_PAGINATED_FORMS({ commit, dispatch }: ActionContext<IFormState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getForm()
        .getPaginatedForms(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableForm>>) => {
          commit('UPDATE_PAGINATED_FORMS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_FORM_BY_ID({ commit, dispatch }: ActionContext<IFormState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getForm()
        .getFormById(id)
        .then((response: ResourceResponse<IForm | null>) => {
          commit('SELECTED_FORM', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_FORM(
    { commit, dispatch }: ActionContext<IFormState, IRootState>,
    payload: Partial<IForm>
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getForm()
        .createForm(payload)
        .then((response: ResourceResponse<IForm>) => {
          commit('UPDATE_SELECTED_FORM', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SELECTED_FORM(
    { commit, dispatch }: ActionContext<IFormState, IRootState>,
    payload: { id: string; body: Partial<IForm> }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getForm()
        .updateForm(payload.id, payload.body)
        .then((response: ResourceResponse<IForm>) => {
          commit('UPDATE_SELECTED_FORM', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_FORM(
    { dispatch }: ActionContext<IFormState, IRootState>,
    id: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getForm()
        .deleteForm(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGINATED_SUBMISSIONS({ commit, dispatch }: ActionContext<IFormState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          const filterOperators = ['createdAt'].includes(element.selectedFilter)
            ? EFilterOperators.BTW
            : EFilterOperators.EQ;
          builder.addFilter(element.selectedFilter, filterOperators, element.value);
        });
      }
      ApiFactory.getForm()
        .getPaginatedFormSubmissions(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableFormSubmission>>) => {
          commit('UPDATE_PAGINATED_FORM_SUBMISSIONS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_SUBMISSION_LINKS_BY_ID(
    { commit, dispatch }: ActionContext<IFormState, IRootState>,
    id: string
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getForm()
        .getSubmissionLinksById(id)
        .then((response: IFormSubmissionLinkResponse) => {
          commit('SELECTED_FORM_SUMISSION_LINKS', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  EXPORT_FORM_SUBMISSION_IN_CSV({ dispatch }: ActionContext<IFormState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      try {
        const formQueryBuilder = new FormSubmissionQueryBuilder();

        if (payload?.sort?.field) {
          formQueryBuilder.setSortBy(payload.sort.field, payload.sort.direction || 'ASC');
        }

        formQueryBuilder.setSearch(payload.search ?? '');

        formQueryBuilder.clearFilter();

        if (payload.filter) {
          payload.filter.forEach((element) => {
            const filterOperator =
              element.selectedFilter === 'createdAt' ? EFilterOperators.BTW : EFilterOperators.EQ;

            const value =
              element.selectedFilter === 'createdAt' && Array.isArray(element.value)
                ? `${element.value[0]},${element.value[1]}`
                : element.value;

            formQueryBuilder.addFilter(element.selectedFilter, filterOperator, value);
          });
        }

        ApiFactory.getForm()
          .exportFormSubmissionInCsv(formQueryBuilder)
          .then((response: string) => {
            resolve(response);
          })
          .catch((errorResponse: unknown) => {
            if (errorResponse instanceof Error) {
              dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
              reject(errorResponse);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }
};

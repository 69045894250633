// eslint-disable-next-line import/order
import {
  ICampaign,
  IProductCampaignStats,
  ITechScanStats,
  ITechScanTimeline
} from '@/modules/analytics/analytics/analytics.interface';
import { IScansList } from '@/modules/common/location.interface';
import { ITech } from '@/modules/common/service.interface';
import { IProduct } from '@/modules/product/product.interface';
import { IScanRecord } from '@connect-saas/types';
import { MutationTree } from 'vuex';
import { AnalyticsState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<AnalyticsState> = {
  SELECTED_TECH(state, selected_tech: ITech) {
    state.selected_tech = selected_tech;
  },
  SELECTED_PRODUCT(state, selected_product: IProduct) {
    state.selected_product = selected_product;
  },
  SELECTED_CAMPAIGN(state, selected_campaign: ICampaign) {
    state.selected_campaign = selected_campaign;
  },
  UPDATE_ANALYTICS_SCAN_STATS(state, analytics_scan_stats: ITechScanStats) {
    state.analytics_scan_stats = analytics_scan_stats;
  },
  UPDATE_ANALYTICS_SCAN_LOCATIONS(state, analytics_scan_locations: IScansList) {
    state.analytics_scan_locations = analytics_scan_locations;
  },
  UPDATE_ANALYTICS_SCANS_TIMELINE(state, analytics_scans_timeline: ITechScanTimeline) {
    state.analytics_scans_timeline = analytics_scans_timeline;
  },
  UPDATE_ANALYTICS_SCANS(state, analytics_scans: IScanRecord) {
    state.analytics_scans = analytics_scans;
  },
  UPDATE_ANALYTICS_CAMPAIGN_STATS(state, analytics_campaign_stats: IProductCampaignStats) {
    state.analytics_campaign_stats = analytics_campaign_stats;
  }
};

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { GeographyState } from './types';

const state: GeographyState = {
  scans_by_location: {
    resources: [
      {
        location: {
          lat: 0,
          lng: 0
        },
        count: 0
      }
    ]
  },
  scans_by_date: {
    resources: [],
    meta: {
      currentPage: 1,
      itemsPerPage: 15,
      totalItems: 5,
      totalPages: 1,
      lastRecord: [0, '0']
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const geography: Module<GeographyState, IRootState> = {
  state,
  getters,
  mutations,
  actions
};

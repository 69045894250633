export enum EScanType {
  CAMPAIGN = 'campaign',
  PAGE = 'page',
  TRACER = 'tracer',
  MOTIFMICRO = 'motifmicro'
}

export enum EScanAuthType {
  PRODUCT_VALIDATION = 'product_validation',
  PROTECTCODE_VERIFICATION = 'protectcode_verification',
  TRACER = 'tracer',
  MOTIFMICRO = 'motifmicro',
  PROTECTCODE_PLUS = 'protectcode_plus'
}

export enum EDimensions {
  AGE = 'age',
  AGE_GENDER = 'ageGender',
  BROWSER = 'browser',
  CAMPAIGN_SCANS = 'campaign',
  CITY = 'city',
  COUNTRY = 'country',
  DEVICE_BRAND = 'deviceBrand',
  DEVICE_MODEL = 'deviceModel',
  GENDER = 'gender',
  OS = 'os',
  PRODUCT_SCANS = 'product',
  AUTH_STATE = 'authState'
}

export enum EScanStats {
  AUTH_STATE = 'authState',
  COUNTRIES = 'countries',
  FAILURE_REASON = 'failureReason',
  SCANS = 'scans',
  VISITORS = 'visitors'
}

export enum EMetrics {
  VISITORS = 'visitors',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  BROWSERS = 'browsers',
  OS = 'os',
  DEVICE_BRANDS = 'deviceBrands',
  DEVICE_MODELS = 'deviceModels'
}

export enum EScanEventType {
  INVALID_PRODUCT_REPORT = 'invalidProductReport',
  INVALID_PROTECTCODE_REPORT = 'invalidProtectCodeReport',
  PRODUCT_VALIDATION = 'productValidation',
  PROTECTCODE = 'protectCode',
  PROTECTCODE_RETRY = 'protectCodeRetry'
}

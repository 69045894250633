import { TFilter } from '@/modules/common/service.type';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';

export default (builder: PaginationBuilder): TFilter => {
  const filterOperatorMap = {
    EQ: '$eq',
    IN: '$in',
    GTE: '$gte',
    GT: '$gt',
    LTE: '$lte',
    LT: '$lt',
    BTW: '$btw'
  };

  // Build Filters
  const filter = {};
  builder.getFilters().forEach((builderFilter) => {
    // Check the Operator is in the map (it's valid)
    if (!filterOperatorMap[builderFilter.operator]) {
      return;
    }

    // Map the operator to the one the API requires
    const operator = filterOperatorMap[builderFilter.operator];

    // Build a comma separated string if the filter value is an array
    const value = Array.isArray(builderFilter.value)
      ? builderFilter.value.join(',')
      : builderFilter.value;

    // Create the filter string
    const filterString = `${operator}:${value}`;

    // Add the filter string to the filters
    if (!filter[builderFilter.field]) {
      // No filter exists for this field so add it
      filter[builderFilter.field] = filterString;
    } else if (Array.isArray(filter[builderFilter.field])) {
      // Array of filters exists for this field already so add filter string to the array
      filter[builderFilter.field].push(filterString);
    } else {
      // Single filter (no array) exists for this field, so convert to array and add new filter string
      filter[builderFilter.field] = [filter[builderFilter.field], filterString];
    }
  });

  return filter;
};

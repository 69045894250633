import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { IUserState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<IUserState, IRootState> = {
  user_allowance_remaining(state: IUserState): boolean {
    const remaining: number | null = state.update_user_allowance?.remaining;
    return remaining === null || remaining > 0;
  },
  user_allowance(state: IUserState): number | null {
    const allowance: number | null = state.update_user_allowance?.allowance;
    return allowance;
  }
};

import { IStatisticsData } from '@/modules/dashboard/dashboard.interface';
import ClientInterface from '@/services/api/common/ClientInterface';

export default class Statistics {
  private readonly client: ClientInterface;

  private readonly resource: string = 'dashboard/stats';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getDashboardStats(from: Date, to: Date): Promise<IStatisticsData> {
    return this.client.get<IStatisticsData>(`${this.resource}?from=${from}&to=${to}`);
  }
}

import { MutationTree } from 'vuex';
import { ReportState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<ReportState> = {
  UPDATE_PAGINATED_REPORT_LIST(state, paginated_report_list) {
    state.paginated_report_list = paginated_report_list;
  },
  UPDATE_REPORT_SCAN_LOCATIONS(state, report_scan_locations) {
    state.report_scan_locations = report_scan_locations;
  },
  UPDATE_REPORT_SCAN_RECORDS(state, report_scan_records) {
    state.report_scan_records = report_scan_records;
  }
};

import { IScansByDatePaginatedMeta } from '@/modules/common/service.interface';

export default class ScansByDateResponse<T> {
  public readonly resources: T[];

  public readonly meta: IScansByDatePaginatedMeta;

  constructor(resources: T[], meta: IScansByDatePaginatedMeta) {
    this.resources = resources;
    this.meta = meta;
  }
}

// eslint-disable-next-line import/order
import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { IProductState } from './types';

const state: IProductState = {
  paginated_products: null,
  update_selected_product: null
};

// eslint-disable-next-line import/prefer-default-export
export const product: Module<IProductState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

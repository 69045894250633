import { EAccountTiers } from '../../enum';

export const SubscriptionStatuses = [
  'trial',
  'active',
  'cancelled',
  'non_renewing',
  'future',
  'paused',
] as const;
export type TSubscriptionStatus = (typeof SubscriptionStatuses)[number];

export const SubscriptionFrequencies = ['monthly', 'yearly'] as const;
export type TSubscriptionFrequency = (typeof SubscriptionFrequencies)[number];

export const SubscriptionTiers = [
  EAccountTiers.LITE,
  EAccountTiers.PLUS,
  EAccountTiers.PRO,
] as const;
export type TSubscriptionTier = (typeof SubscriptionTiers)[number];

export interface ISubscription {
  accountId: string;
  createdAt: Date;
  currency: string;
  frequency: TSubscriptionFrequency;
  id: string;
  nextBillingAt: Date;
  amount: number;
  status: TSubscriptionStatus;
  tier: TSubscriptionTier;
  trialEnd?: Date;
  updatedAt: Date;
  version: number;
  cancelledAt?: Date;
}

// export interface ICampaign {
//   campaign: string;
//   tier: TSubscriptionTier;
//   frequency: TSubscriptionFrequency;
//   trialLength: number;
//   campaignEndAt?: Date;
// }

export interface ICampaignConfig {
  campaign: string;
  tier: TSubscriptionTier;
  frequency: TSubscriptionFrequency;
  trialLength: number;
  campaignEndAt?: Date;
}

export interface ISubscriptionConfig {
  tier: TSubscriptionTier;
  frequency: TSubscriptionFrequency;
  trialLength: number | null;
  campaigns: ICampaignConfig[];
}

export interface ISubscriptionTier {
  tier: TSubscriptionTier;
  frequency: TSubscriptionFrequency;
  trialEndAt: Date;
}

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { IMiscState } from './types';

const state: IMiscState = {
  paginated_misc_scans: null,
  analytics_stat_counts: null,
  analytics_scan_locations: null,
  analytics_most_scanned_products: null,
  analytics_scan_time_series: null
};

// eslint-disable-next-line import/prefer-default-export
export const misc: Module<IMiscState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

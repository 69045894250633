/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { EErrorStatus } from '@/modules/error/error.enum';
import Api from '@/services/api/ApiFactory';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import {
  IConfigurableIntegrations,
  ICreateIntegrationParams,
  TIntegration
} from '@/views/private/integration/types/interface';
import { TIntegrationType } from '@/views/private/integration/types/type';
import { ILinkState } from './types';

const ApiFactory = new Api();

export const actions: ActionTree<ILinkState, IRootState> = {
  GET_INTEGRATION_LIST({
    commit,
    dispatch
  }: ActionContext<ILinkState, IRootState>): Promise<IConfigurableIntegrations> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .getAllIntegration<IConfigurableIntegrations>()
        .then((response: IConfigurableIntegrations) => {
          commit('UPDATE_INTEGRATION_LIST', response);
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            if (errorResponse?.response?.status !== EErrorStatus.NotFound) {
              dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            }
            reject(errorResponse);
          }
        });
    });
  },
  GET_INTEGRATION_BY_TYPE(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    type: TIntegrationType
  ): Promise<TIntegration> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration(type)
        .getIntegrationData<TIntegration>()
        .then((response: TIntegration) => {
          commit('UPDATE_SELECTED_INTEGRATION', response);
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            if (errorResponse?.response?.status !== EErrorStatus.NotFound) {
              dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            }
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_INTEGRATION(
    { commit, dispatch }: ActionContext<ILinkState, IRootState>,
    payload: ICreateIntegrationParams
  ): Promise<ResourceResponse<TIntegration>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration(payload.type)
        .createIntegration(payload.data)
        .then((response: ResourceResponse<TIntegration>) => {
          commit('UPDATE_SELECTED_INTEGRATION', response.resource);
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_INTEGRATION(
    { dispatch }: ActionContext<ILinkState, IRootState>,
    type: TIntegrationType
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration(type)
        .deleteIntegration()
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};

import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { IMediaState } from './types';

const state: IMediaState = {
  uploaded_media: null,
  update_file: null,
  update_folder: null,
  current_folder: null,
  new_added_media: [],
  remove_media_file: [],
  update_search_media_file_and_folder: null,
  update_media_folder: null,
  selected_media: null
};

// eslint-disable-next-line import/prefer-default-export
export const media: Module<IMediaState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};

export default class ResourceResponse<T> {
  public readonly status: number;

  public readonly resource: T;

  constructor(status: number, resource: T) {
    this.status = status;
    this.resource = resource;
  }
}

/* eslint-disable import/no-extraneous-dependencies */
import '@/helpers/vuetify-css';
import { createApp } from 'vue';
import { PureAbility } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueGoogleCharts from 'vue-google-charts';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import 'vue-svg-inline-plugin/src/polyfills';
import { createGtm } from '@gtm-support/vue-gtm';
import { createHead } from '@unhead/vue';
import { MotionPlugin } from '@vueuse/motion';
import router from './router';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import { store } from './store';
import * as Sentry from '@sentry/vue';

import App from './app/App.vue';

const ability = new PureAbility();
const app = createApp(App);
const head = createHead();
const isProduction = import.meta.env.MODE === 'production';

const gtmId = isProduction ? 'GTM-KT5L7XKC' : 'GTM-WT9H7XF5';

app.use(head);
app.use(VueDOMPurifyHTML, {
  hooks: {
    beforeSanitizeAttributes: (node) => {
      if (node.tagName === 'A') {
        if (node.hasAttribute('target')) {
          node.setAttribute('data-temp-href-target', node.getAttribute('target'));
        }
      }
    },
    afterSanitizeAttributes: (node) => {
      if (node.tagName === 'A' && node.hasAttribute('data-temp-href-target')) {
        node.setAttribute('target', node.getAttribute('data-temp-href-target'));
        node.removeAttribute('data-temp-href-target');
        if (node.getAttribute('target') === '_blank') {
          node.setAttribute('rel', 'noopener noreferrer');
        }
      }
    }
  }
});
app.use(VueGoogleCharts);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(abilitiesPlugin, ability);
app.use(VueSvgInlinePlugin);
app.use(
  createGtm({
    id: gtmId,
    vueRouter: router
  })
);
app.use(MotionPlugin);
Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 50% of the transactions
  tracePropagationTargets: [
    /^https:\/\/dashboard.staging.connectqr\.ai\/api/,
    /^https:\/\/dashboard.connectqr\.ai\/api/
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app.mount('#root');

if ('serviceWorker' in navigator) {
  // unregister all service workers on connectqr domain
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      for (const registration of registrations) {
        registration
          .unregister()
          .then(() => {
            console.log('Service worker unregistered:', registration);
          })
          .catch((error) => {
            console.error('Failed to unregister service worker:', error);
          });
      }
    })
    .catch((error) => {
      console.error('Error fetching service workers:', error);
    });

  // clear all caches related to connectqr domain
  if (window?.caches) {
    caches
      .keys()
      .then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches
            .delete(cacheName)
            .then(() => {
              console.log(`Cache ${cacheName} deleted.`);
            })
            .catch((error) => {
              console.error(`Failed to delete cache ${cacheName}:`, error);
            });
        });
      })
      .catch((error) => {
        console.error('Error fetching caches:', error);
      });
  }
}

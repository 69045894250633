import {
  INotification,
  INotificationResponse
} from '@/modules/notification/notification.interface';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class Notification {
  private readonly client: ClientInterface;

  private readonly resource: string = 'notifications';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAllNotifications(unread: boolean): Promise<INotificationResponse> {
    return this.client.get(`${this.resource}?unread=${unread}`);
  }

  markNotificationAsRead(notificationId: string): Promise<ResourceResponse<INotification>> {
    return this.client.post(`${this.resource}/${notificationId}/read`);
  }

  markAllNotificationsAsRead(): Promise<ResourceResponse<boolean>> {
    return this.client.post(`${this.resource}/read`);
  }
}

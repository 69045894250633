import {
  IAlertFilters,
  IAlertTimeline,
  IProductCountryScansMapItem,
  IProductCountryScansReportItem,
  IRule,
  IScansOverTimeAlert,
  IScansOverTimeCountryScans,
  IScansOverTimeReportItem,
  ISuspiciousCodeList
} from '@/modules/brand-protection/brand-protection.interface';
import { IScansList } from '@/modules/common/location.interface';
import ClientInterface from './common/ClientInterface';
import GeohashScansBuilder from './common/GeohashScansBuilder';
import GeohashScansResponse from './common/GeohashScansResponse';
import PaginatedResponse from './common/PaginatedResponse';
import ResourceResponse from './common/ResourceResponse';

export default class BrandProtection {
  private readonly client: ClientInterface;

  private readonly resource: string = 'brand-protection';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAlertLocations(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScansList>> {
    builder.setResource(`${this.resource}/alert-locations`);
    return this.client.getScansByGeohash<IScansList>(builder);
  }

  getAlertTimeline(payload: IAlertFilters): Promise<IAlertTimeline> {
    return this.client.get<IAlertTimeline>(
      `${this.resource}/alert-timeline?from=${payload.start}&to=${payload.end}&granularity=${payload.granularity}`
    );
  }

  getPaginatedSuspiciousCodes(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<PaginatedResponse<ISuspiciousCodeList>> {
    builder.setResource(`${this.resource}/suspicious-codes`);
    return this.client.getPaginated<ISuspiciousCodeList>(builder);
  }

  getBrandProtectionRules(): Promise<ResourceResponse<IRule[]>> {
    return this.client.get(`${this.resource}/rule`);
  }

  getBrandProtectionRuleById(id: string | number): Promise<ResourceResponse<IRule>> {
    return this.client.getById(`${this.resource}/rule`, id);
  }

  getScansOverTimeReport(): Promise<ResourceResponse<IScansOverTimeReportItem[]>> {
    return this.client.get(`${this.resource}/report/scans-over-time`);
  }

  getScansOverTimeLocations(
    builder: GeohashScansBuilder = new GeohashScansBuilder()
  ): Promise<GeohashScansResponse<IScansList>> {
    builder.setResource(`${this.resource}/report/scans-over-time/locations`);
    return this.client.getScansByGeohash<IScansList>(builder);
  }

  getScansOverTimeCountries(): Promise<ResourceResponse<IScansOverTimeCountryScans[]>> {
    return this.client.get(`${this.resource}/report/scans-over-time/countries`);
  }

  getProductCountryScansReport(): Promise<IProductCountryScansReportItem[]> {
    return this.client.get(`${this.resource}/report/product-country-scans`);
  }

  getProductCountryScansMap(): Promise<IProductCountryScansMapItem[]> {
    return this.client.get(`${this.resource}/report/product-country-scans/map`);
  }

  createAlertRule(alert: IRule): Promise<ResourceResponse<IRule>> {
    return this.client.create<IRule, IRule>(`${this.resource}/rule`, alert);
  }

  updateAlertRule(id: string | number, body: IRule): Promise<ResourceResponse<IRule>> {
    return this.client.update<IRule, IRule>(`${this.resource}/rule`, id, body);
  }

  deleteAlertRule(id: string | number): Promise<ResourceResponse<IRule>> {
    return this.client.delete(`${this.resource}/rule`, id);
  }

  stopAlertRule(id: string | number): Promise<ResourceResponse<IRule>> {
    return this.client.post(`${this.resource}/alert/${id}/stop`);
  }

  getAlertDetailsByRuleId(id: string | number): Promise<ResourceResponse<IScansOverTimeAlert>> {
    return this.client.getById(`${this.resource}/alert`, id);
  }
}

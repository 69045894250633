import { IPaginateQuery } from '@connect-saas/util/common';

export const buildQueryString = (query: IPaginateQuery) => {
  const params: string[] = [];

  const filterOperatorMap: { [key: string]: string } = {
    EQ: '$eq',
    IN: '$in',
    GTE: '$gte',
    GT: '$gt',
    LTE: '$lte',
    LT: '$lt',
    BTW: '$btw'
  };

  if (query.filter) {
    Object.entries(query.filter).forEach(([key, value]) => {
      let operator = 'EQ'; // Default operator

      if (typeof value === 'string' && value.includes(':')) {
        // extract the operator and value by splitting only at the first occurrence of ':'
        const [op, ...rest] = value.split(':');
        const val = rest.join(':'); // Rejoin the rest of the value to preserve any colons in the date
        operator = op;

        // push the filter with the corresponding operator
        params.push(`filter.${key}=${filterOperatorMap[operator]}:${val}`);
      } else {
        // handle non-formatted values with the default operator
        params.push(`filter.${key}=${filterOperatorMap[operator]}:${value}`);
      }
    });
  }

  if (query.sortBy && query.sortBy.length > 0) {
    query.sortBy.forEach(([field, direction]) => {
      params.push(`sortBy=${field}:${direction}`);
    });
  }

  if (query.search) {
    params.push(`search=${query.search}`);
  }

  if (query.searchBy && query.searchBy.length > 0) {
    query.searchBy.forEach((field) => {
      params.push(`searchBy=${field}`);
    });
  }

  return params.join('&'); // manually join the parameters into a query string
};

// import { INotification } from '@/modules/notification/notification.interface';
import { INotification, IWebSocketMessage } from '@/modules/notification/notification.interface';
import { IAiQr } from '@connect-saas/types';
import { onMounted, onUnmounted, ref } from 'vue';

const useWebSocket = (url: string, store) => {
  const socket = ref<WebSocket | null>(null);

  const connect = () => {
    socket.value = new WebSocket(url);

    socket.value.addEventListener('open', () => {
      console.log('WebSocket connected');
    });

    socket.value.addEventListener('message', (event: MessageEvent<any>) => {
      const message: IWebSocketMessage = JSON.parse(event.data);
      if (message?.type === 'notification') {
        store.commit('notification/UPDATE_NOTIFICATIONS_VIA_WEB_SOCKET', message?.payload);
        store.commit('SHOW_TOAST_MESSAGE', {
          message: (message?.payload as INotification)?.message,
          color: 'info'
        });
      } else if (message?.type === 'ai_qr_code_update') {
        store.commit('link/UPDATE_SELECTED_AI_QR', message?.payload);
        if ((message?.payload as IAiQr)?.status === 'complete') {
          store.commit('SHOW_TOAST_MESSAGE', {
            message: 'Your AI QR image has already been generated',
            color: 'info'
          });
        }
        if ((message?.payload as IAiQr)?.status === 'error') {
          if ((message?.payload as IAiQr)?.error === 'nsfw content detected') {
            store.commit('link/UPDATE_AI_QR_ERROR', (message?.payload as IAiQr)?.error);
            return;
          }
          store.commit('SHOW_TOAST_MESSAGE', {
            message: (message?.payload as IAiQr)?.error,
            color: 'error'
          });
        }
      }
    });

    socket.value.addEventListener('close', () => {
      console.log('WebSocket disconnected');
    });
  };

  const disconnect = () => {
    if (socket.value) {
      socket.value.close();
      socket.value = null;
    }
  };

  onMounted(() => {
    connect();
  });

  onUnmounted(() => {
    disconnect();
  });

  return {
    socket,
    connect,
    disconnect,
    store
  };
};

export default useWebSocket;

export enum ETechActivationMethod {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual'
}

export enum ETechBatchStatus {
  INITIATED = 'initiated',
  GENERATING = 'generating',
  ERROR = 'error',
  READY = 'ready'
}

export enum EProtectCodeFormat {
  NUMERIC = 'numeric',
  ALPHA_NUMERIC = 'alpha_numeric',
  ALPHA_NUMERIC_READABLE = 'alpha_numeric_readable'
}

export enum ETechBatchType {
  QR = 'qr',
  PROTECTCODE_PLUS = 'protectcode-plus'
}

export enum ETechBatchFileGenerationStatus {
  WAITING = 'waiting',
  GENERATING = 'generating',
  WAITING_UPLOAD = 'waiting_upload',
  UPLOADING = 'uploading',
  ERROR = 'error',
  DONE = 'done'
}

export interface IPaginationMeta {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
  lastRecord?: string[];
}

export interface IPaginatedData<T> {
  data: T[];
  meta: IPaginationMeta;
}

export interface IQueryFilter {
  [key: `filter.${string}`]: string;
}

export interface IFilter {
  field: string;
  condition: string;
  value: number[] | string | string[];
}

export interface IOrder {
  field: string;
  order: string;
}

export interface IPaginationPayload {
  start: string;
  end: string;
  page: number;
  itemsPerPage: number;
}

export type IPaginateOrder = [string, 'ASC' | 'DESC'];
export type IPaginateSortBy = IPaginateOrder[];

export enum EFilterOperators {
  EQ = 'EQ',
  IN = 'IN',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  BTW = 'BTW'
}

export enum EPaginateQueryFilterOperator {
  EQ = '$eq',
  GT = '$gt',
  GTE = '$gte',
  IN = '$in',
  NULL = '$null',
  LT = '$lt',
  LTE = '$lte',
  BTW = '$btw',
  NOT = '$not',
  ILIKE = '$ilike'
}

export interface IPaginatePageLimit {
  page?: number;
  limit?: number;
}

export interface IPaginateQuery extends IPaginatePageLimit {
  sortBy?: [string, string][];
  searchBy?: string[];
  search?: string;
  filter?: { [column: string]: string | string[] };
  path: string;
}

export interface IPaginateConfig {
  rangeFields?: string[];
  sortableColumns: string[];
  searchableColumns?: string[];
  select?: string[];
  maxLimit?: number;
  defaultSortBy?: IPaginateSortBy;
  defaultLimit?: number;
  filterableColumns?: { [key: string]: EPaginateQueryFilterOperator[] };
}

export type TFilterTokens = [
  null | EPaginateQueryFilterOperator.NOT,
  EPaginateQueryFilterOperator,
  string | EPaginateQueryFilterOperator.NULL
];

export interface IPaginateQueryFilterToken {
  isNot: boolean;
  operator: EPaginateQueryFilterOperator;
  value: string | EPaginateQueryFilterOperator.NULL;
}

export interface IPaginatedQueryFilters {
  [column: string]: IPaginateQueryFilterToken[];
}

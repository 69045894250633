import { ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
import { GeographyState } from './types';

const ApiFactory = new Api();
const builder = new GeohashScansBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<GeographyState, IRootState> = {
  GET_SCANS_BY_LOCATION({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.start);
      builder.setToDate(payload.end);
      builder.setBoundingBox(payload.boundingBox);

      ApiFactory.getScansByLocation()
        .getGeohashScansByLocation(builder)
        .then((response) => {
          commit('UPDATE_SCANS_BY_LOCATION', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_GEOGRAPHIC_SCANS_BY_DATE({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.start);
      builder.setToDate(payload.end);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      builder.setSearchAfter(payload.searchAfter);

      ApiFactory.getScan()
        .getScansByDate(builder)
        .then((response) => {
          commit('UPDATE_SCANS_BY_DATE', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};

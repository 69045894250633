import { all } from '@vee-validate/rules';
import { defineRule } from 'vee-validate';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  isFacebookUrl,
  isInstagramUrl,
  isLinkedInURL,
  isSpotifyUrl,
  isTikTokUrl,
  isTwitterURL,
  isWeChatUrl,
  isYouTubeUrl
} from '@ypbconnectplatform/connectqr-common';

Object.keys(all).forEach((rule) => {
  if (rule !== 'default') {
    defineRule(rule, all[rule]);
  }
});

defineRule('required', (value) => {
  if (
    !value ||
    (typeof value !== 'number' &&
      typeof value !== 'boolean' &&
      typeof value !== 'object' &&
      !value.length) ||
    (value.constructor === Object && Object.keys(value)?.length === 0) ||
    (value.constructor === Array && value?.length === 0)
  ) {
    return 'This field is required';
  }
  return true;
});

defineRule('max_value', (value, [max]) => {
  if (value > max) {
    return 'Maximum value exceeded. Please enter a smaller value.';
  }
  return true;
});

defineRule('min_value', (value, [min]) => {
  if (value < min) {
    return 'Minimum value exceeded. Please enter a bigger value.';
  }
  return true;
});

defineRule('max', (value, [max]) => {
  if (value?.length > max && value?.length !== 0) {
    return `Please input less than ${max} characters`;
  }
  return true;
});

defineRule('min', (value, [min]) => {
  if (value?.length < min && value?.length !== 0) {
    return `Pleae input at least ${min} characters`;
  }
  return true;
});

defineRule('location', (value) => {
  if (!/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(value)) {
    return 'Enter a valid latitude longitude';
  }
  return true;
});

defineRule('hexa', (value) => {
  if (!/^#(?:(?:[0-9a-fA-F]{3}){1,2}|(?:[0-9a-fA-F]{4}){1,2})$/.test(value) && !!value) {
    return 'Enter a valid color code';
  }
  return true;
});

defineRule('valid_domain', (value) => {
  if (
    !!value &&
    !/^(?:(?:https?):\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,})(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i.test(
      value
    )
  ) {
    return 'Enter a valid URL';
  }
  return true;
});

defineRule('valid_short_url_domain', (value) => {
  if (
    !!value &&
    // eslint-disable-next-line no-useless-escape
    !/^(https?:\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,})(\/[-a-zA-Z\d%_.~+&:@]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_:.~+=%;:@&\/,]*)?$/i.test(
      value
    )
  ) {
    return 'Enter a valid URL';
  }
  return true;
});

defineRule('valid_spotify_url', (value: string) => {
  if (!value || !isSpotifyUrl(value)) {
    return 'Enter a valid Spotify URL';
  }
  return true;
});

defineRule('valid_facebook_url', (value: string) => {
  if (!value || !isFacebookUrl(value)) {
    return 'Enter a valid Facebook URL';
  }
  return true;
});

defineRule('valid_twitter_url', (value: string) => {
  if (!value || !isTwitterURL(value)) {
    return 'Enter a valid X(Twiiter) URL';
  }
  return true;
});

defineRule('valid_youtube_url', (value: string) => {
  if (!value || !isYouTubeUrl(value)) {
    return 'Enter a valid Youtube URL';
  }
  return true;
});

defineRule('valid_instagram_url', (value: string) => {
  if (!value || !isInstagramUrl(value)) {
    return 'Enter a valid Instagram URL';
  }
  return true;
});

defineRule('valid_linkedin_url', (value: string) => {
  if (!value || !isLinkedInURL(value)) {
    return 'Enter a valid LinkedIn URL';
  }
  return true;
});

defineRule('valid_tiktok_url', (value: string) => {
  if (!value || !isTikTokUrl(value)) {
    return 'Enter a valid TikTok URL';
  }
  return true;
});

defineRule('valid_wechat_url', (value: string) => {
  if (!value || !isWeChatUrl(value)) {
    return 'Enter a valid WeChat URL';
  }
  return true;
});

defineRule('valid_password', (value) => {
  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$-/:-?{-~!"^_`\\[\]@#])/.test(value)) {
    return 'Enter a valid password';
  }
  return true;
});

import { GetterTree } from 'vuex';
import { IOSList } from '@/modules/analytics/analytics/analytics.interface';
import {
  IAnalyticsScanStat,
  IBrowserList
} from '@/modules/analytics/technology/technology.interface';
import { IRootState } from '@/modules/common/common.interface';
import { TechnologyState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<TechnologyState, IRootState> = {
  technology_scan_data(state): IAnalyticsScanStat {
    return state.technology_scan_data;
  },
  technology_browser_list(state): IBrowserList {
    return state.technology_browser_list;
  },
  technology_os_list(state): IOSList {
    return state.technology_os_list;
  }
};

import { EDateHistogramGranularity } from '@/modules/analytics-report/analytics-report.enum';
import { IRootState } from '@/modules/common/common.interface';
import { TPayload } from '@/modules/common/common.type';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
// eslint-disable-next-line import/order
import { ActionTree } from 'vuex';
import { AnalyticsReportState } from './types';

const ApiFactory = new Api();
const builder = new GeohashScansBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<AnalyticsReportState, IRootState> = {
  GET_ANALYTICS_REPORT({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReports(builder)
        .then((response) => {
          commit('UPDATE_ANALYTICS_REPORT', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  CREATE_ANALYTICS_REPORT_BY_ID({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .createAnalyticsReportById(payload)
        .then((response) => {
          dispatch('GET_ANALYTICS_REPORT_BY_ID', response.resource.id);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_BY_ID({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportById(id)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPDATE_ANALYTICS_REPORT_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .updateAnalyticsReportById(payload.id, payload)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT', response.resource);
          dispatch('GET_ANALYTICS_REPORT_BY_ID', response.resource.id);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_EXPORT_BY_ID({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportExportById(id)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT_EXPORT', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_ANALYTICS_REPORT({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .deleteAnalyticsReportById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_SCANS_COMPONENT_BY_ID({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportScansComponentById(payload.id, builder)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT_SCANS_COMPONENT', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_SCAN_LOCATIONS_COMPONENTS_BY_ID({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      if (payload.boundingBox) {
        builder.setBoundingBox(payload.boundingBox);
      }
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportScanLocationsById(payload.id, builder)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT_SCAN_LOCATIONS', response.resources);
          resolve(response.resources);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_SCAN_TIME_SERIES_BY_ID(
    { commit, dispatch },
    payload: TPayload<EDateHistogramGranularity>
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportScanTimeSeries(payload)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT_SCAN_TIME_SERIES', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_FILTER_LOOKUPS({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportFilterLookups()
        .then((response) => {
          commit('UPDATE_ANALYTICS_REPORT_FILTER_LOOKUPS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_REPORT_SCAN_STATS_BY_ID({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setScanType(payload.scanType);
      ApiFactory.getAnalyticsReport()
        .getAnalyticsReportScanStats(payload)
        .then((response) => {
          commit('SELECTED_ANALYTICS_REPORT_SCAN_STATS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};

import { IMetaDataFieldsResponse } from '@/modules/metadata/metadata.interface';
import { IQrSearchFilter, IQrSearchResponse } from '@/modules/tech/tech.interface';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class Qr {
  private readonly client: ClientInterface;

  private resource = 'tech';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getTechMetaData(): Promise<ResourceResponse<IMetaDataFieldsResponse>> {
    return this.client.get(`${this.resource}/metadata`);
  }

  searchQrByProductOrBatch(filter: IQrSearchFilter): Promise<ResourceResponse<IQrSearchResponse>> {
    const result = Object.entries(filter).reduce((acc, [k, v]) => {
      if (v !== null) {
        const filterString = `${k}=${v}`;
        acc.push(filterString);
      }
      return acc;
    }, []);
    const parsedFilterQuery = result.join('&');
    return this.client.get(`${this.resource}/qr?${parsedFilterQuery}`);
  }
}
